import { useNavigate } from 'react-router';

import { ProDevisView } from '@travauxlib/shared/src/types/api/pro/ProDevisView';

import { getDevisUrl } from 'utils/urls';

type HandleClickItem = (
  devis: ProDevisView,
  event: React.MouseEvent<Element, MouseEvent>,
) => void | Window | null;

export const useNavigateToDevis = (): HandleClickItem => {
  const navigate = useNavigate();

  const handleClickItem = (
    devis: ProDevisView,
    event: React.MouseEvent<Element, MouseEvent>,
  ): void => {
    const link = getDevisUrl(devis);
    const external = ['sent', 'signed', 'sent_hemea', 'rejected_hemea'].includes(devis.status);
    const clickOnIgnoredElement = (event.target as HTMLElement).closest(
      '.ignore-move-to-devisfacture',
    );

    if (clickOnIgnoredElement) {
      return undefined;
    }

    if (external) {
      window.open(link);
    } else {
      navigate(link);
    }
  };

  return handleClickItem;
};
