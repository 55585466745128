import React from 'react';

import classNames from 'classnames';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { ProDevisView } from '@travauxlib/shared/src/types/api/pro/ProDevisView';

import { useActivatedMarginCalculation } from 'api/profileSelectors';
import { DevisActions } from 'components/DevisActions';
import { StatusTagsDevis } from 'components/StatusTagsDevis';
import { WarnForReplacementTooltip } from 'components/WarnForReplacementTooltip';
import { DevisCardDate } from 'features/Deals/features/Devis/components/DevisList/components/DevisCard/DevisCardDate';
import { DevisViewCount } from 'features/Deals/features/Devis/components/DevisViewCount';
import { useNavigateToDevis } from 'features/Deals/features/Devis/hooks/useNavigateToDevis';

type Props = {
  devis: ProDevisView;
  isHemea: boolean;
};

export const DevisRow: React.FC<Props> = ({ devis, isHemea }) => {
  const activatedMarginCalculation = useActivatedMarginCalculation();
  const navigateToDevis = useNavigateToDevis();

  const {
    isHighlighted,
    archived,
    title,
    numero = '',
    replacedSignedDevisToken,
    dealUuid,
    status,
    prixTotalHT,
    montantMargeTotale,
    viewCount,
  } = devis as ProDevisView & { isHighlighted: boolean };

  return (
    <tr
      className={classNames('cursor-pointer *:bg-primarylighter', {
        'hover:*:bg-primarylight': !isHighlighted,
        '*:opacity-50 hover:*:bg-primarylighter': archived,
        'transition-colors hover:*:bg-primarylight': isHighlighted,
      })}
      data-testid={`devis-facture-ligne-${title}`}
      onClick={e => {
        navigateToDevis(devis, e);
      }}
    >
      <td className="py-xmd !align-middle pl-xmd rounded-tl-xxs rounded-bl-xxs">
        <div className="flex items-center">
          {numero || ''}
          {replacedSignedDevisToken && status !== 'signed' && (
            <WarnForReplacementTooltip
              className="ml-xs"
              replacedSignedDevisToken={replacedSignedDevisToken}
              dealUuid={dealUuid}
            />
          )}
        </div>
      </td>
      <td className="py-xmd !align-middle">{title}</td>
      <td className="py-xmd !align-middle">
        <EURCurrency amount={prixTotalHT} />
      </td>
      {activatedMarginCalculation && (
        <td className="py-xmd !align-middle">
          <EURCurrency amount={montantMargeTotale} />
        </td>
      )}
      <td className="py-xmd !align-middle">
        <StatusTagsDevis devis={devis} disabled={isHemea ?? false} />
      </td>
      <td className="py-xmd !align-middle">
        <DevisCardDate devis={devis} />
      </td>
      <td className="py-xmd !align-middle">
        <DevisViewCount viewCount={viewCount} />
      </td>
      <td className="py-xmd !align-middle pr-xmd rounded-tr-xxs rounded-br-xxs ignore-move-to-devisfacture">
        <div
          className="!flex"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <DevisActions devis={devis} />
        </div>
      </td>
    </tr>
  );
};
