import { useQuery } from '@tanstack/react-query';

import { DealView } from '@travauxlib/shared/src/types/api/pro/DealView';
import { request } from '@travauxlib/shared/src/utils/request';

export const DEAL_KEY = 'deal';

export const useDeal = (dealUuid?: string): DealView | undefined => {
  const { data: deal, isLoading } = useQuery<DealView | undefined>({
    queryKey: [DEAL_KEY, dealUuid],
    queryFn: async () => {
      const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}`;
      if (!dealUuid) {
        return null;
      }
      return request(requestURL);
    },
  });

  if (!deal || isLoading) {
    return undefined;
  }

  return deal;
};
