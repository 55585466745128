import { useMemo } from 'react';

import { ProDevisFactureLigneView } from '@travauxlib/shared/src/types/api/common/ProDevisFactureLotView';

const minMatchCharLength = 3;

export const useSearchDevisLignes = (
  lignes: Array<ProDevisFactureLigneView & { lotLabel: string }>,
  search: string = '',
): ProDevisFactureLigneView[] =>
  useMemo(() => {
    if (!search || search.length < minMatchCharLength) {
      return lignes;
    }

    const lowerSearch = search.toLowerCase();

    return lignes.filter(
      ligne =>
        ligne.designation.toLowerCase().includes(lowerSearch) ||
        ligne.lotLabel.toLowerCase().includes(lowerSearch) ||
        ligne.description?.toLowerCase().includes(lowerSearch),
    );
  }, [lignes, search]);
