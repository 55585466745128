import React from 'react';

import StarFull from '@travauxlib/shared/src/components/DesignSystem/assets/StarFilled.svg?react';
export const FiveStarsExcellentTrustpilot: React.FC = () => (
  <div className="flex items-center pl-[1.75rem]">
    <div className="text-primary w-xl h-xl flex">
      <StarFull />
    </div>
    <div className="text-primary w-xl h-xl">
      <StarFull />
    </div>
    <div className="text-primary w-xl h-xl">
      <StarFull />
    </div>
    <div className="text-primary w-xl h-xl">
      <StarFull />
    </div>
    <div className="text-primary w-xl h-xl">
      <StarFull />
    </div>
    <div className="text-b1 ml-md">Excellent sur Trustpilot</div>
  </div>
);
