import React from 'react';

import classNames from 'classnames';
import { Link as RawLink, useNavigate } from 'react-router';

type Props = {
  to: string;
  id?: string;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
};

export const Link: React.FC<Props> = ({ to, children, onClick, ...props }) => {
  const navigate = useNavigate();
  const { className, ...rest } = props;

  return (
    <RawLink
      to={to}
      {...rest}
      className={classNames(className)}
      data-testid="link"
      onClick={e => {
        if (e.metaKey || e.ctrlKey) {
          return;
        }
        e.preventDefault();
        const {
          location: { protocol, host },
        } = window;
        const baseUrl = `${protocol}//${host}`;
        if (onClick) {
          onClick();
        }
        if (window.swUpdate) {
          window.location.href = `${baseUrl}${to}`;
        } else {
          navigate(to);
        }
      }}
    >
      {children}
    </RawLink>
  );
};
