import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { Facture } from 'types';
import { getFactureUrl } from 'utils/urls';

import { FACTURES_KEY } from './useFacturesList';

const handleCreateAvoir = ({ dealUuid }: { dealUuid: string }): Promise<Facture> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/factures/avoir`;
  return request(requestURL, {
    method: 'POST',
  });
};

export const useCreateAvoir = (): typeof handleCreateAvoir => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutateAsync: createAvoir } = useMutation({
    mutationFn: handleCreateAvoir,
    onSuccess: (facture: Facture) => {
      navigate(getFactureUrl(facture));
      queryClient.invalidateQueries({ queryKey: [FACTURES_KEY, facture.dealUuid] });
    },
    onError: () => {
      toast.error("L'avoir n'a pas pu être créé");
    },
  });

  return createAvoir;
};
