import { useQueryClient, useMutation } from '@tanstack/react-query';

import { ProUserProfileView } from '@travauxlib/shared/src/types/api/common/ProUserProfileView';
import { request } from '@travauxlib/shared/src/utils/request';

import { AUTH_KEY } from './useProfile';

const handleLogin = (body: { email: string; password: string }): Promise<ProUserProfileView> => {
  const requestURL = `${APP_CONFIG.apiURL}/pro/users/login`;
  return request(requestURL, {
    method: 'POST',
    body,
  });
};

export const useLogin = (): typeof handleLogin => {
  const queryClient = useQueryClient();
  const { mutateAsync: login } = useMutation({
    mutationFn: handleLogin,
    onSuccess: (profile: ProUserProfileView) => {
      queryClient.setQueryData([AUTH_KEY], profile);
    },
  });
  return login;
};
