import React, { FC } from 'react';

import classNames from 'classnames';

import Download from '@travauxlib/shared/src/components/DesignSystem/assets/DownloadFilled.svg?react';

import { ImgThumbnail } from 'components/ImgThumbnail';
import { imgExtensions } from 'utils/constants';

type AttachmentProps = {
  url: string;
  filename: string;
  className?: string;
};

export const MessageAttachment: FC<AttachmentProps> = ({ filename, url, className }) => {
  const match = /[^/]*$/.exec(filename);
  const fileNameWithoutPath = match ? match[0] : filename;
  const lastPointIndex = fileNameWithoutPath.lastIndexOf('.');
  const extension = fileNameWithoutPath.substring(lastPointIndex, fileNameWithoutPath.length);
  const fileNameWithoutExtensions = fileNameWithoutPath.substring(0, lastPointIndex);
  return (
    <div
      className={classNames('!flex !items-center !overflow-hidden !truncate', className)}
      key={url}
    >
      {imgExtensions.some(ext => filename.toLowerCase().endsWith(ext)) && (
        <ImgThumbnail src={url} />
      )}
      <span className="!shrink !truncate !font-bold">{fileNameWithoutExtensions}</span>
      <span className="!font-bold !mr-sm">{extension}</span>
      <a className="!shrink-0 ml-auto" href={url} target="_blank" rel="noopener noreferrer">
        <Download className="w-lg h-lg" />
      </a>
    </div>
  );
};
