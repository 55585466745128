import { ProDevisFactureLigneView } from '@travauxlib/shared/src/types/api/common/ProDevisFactureLotView';
import { ProDevisView } from '@travauxlib/shared/src/types/api/pro/ProDevisView';
import { request } from '@travauxlib/shared/src/utils/request';

import { DevisTemplate } from 'types';

export const useGetDevisFromDevisTemplate =
  () =>
  async (devisTemplate: DevisTemplate): Promise<ProDevisView> => {
    const requestURL = `${APP_CONFIG.proApiURL}/devis-templates/${devisTemplate.uuid}`;
    const result: DevisTemplate = await request(requestURL);

    return {
      title: result.title,
      lots:
        result.lots?.map(({ label, items }) => ({
          label,
          items: items
            .filter(({ type }) => type === 'ligne')
            .map((ligne: ProDevisFactureLigneView) => ({ ...ligne, quantite: 1 })),
        })) || [],
    } as ProDevisView;
  };
