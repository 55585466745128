import React, { useEffect } from 'react';

import { useLocation } from 'react-router';

import { getUrlParam } from '@travauxlib/shared/src/utils/urls';

import { useVerifyEmail } from 'api/useVerifyEmail';

export const VerifyEmail: React.FC = () => {
  const location = useLocation();
  const token = getUrlParam(location, 'token')!;
  const verifyEmail = useVerifyEmail(token);
  useEffect(() => {
    if (token) {
      verifyEmail();
    }
  }, []);
  return null;
};
