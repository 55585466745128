import { useEffect } from 'react';

import { useOpenClubProPaywall } from './useOpenClubProPaywall';

export const useClubProPaywall = ({
  isExistingDeal,
  isAllowed,
}: {
  isExistingDeal: boolean;
  isAllowed: boolean;
}): void => {
  const openClubProPaywallModal = useOpenClubProPaywall({ isExistingDeal });

  useEffect(() => {
    if (!isAllowed) {
      openClubProPaywallModal();
    }
  }, [isAllowed]);
};
