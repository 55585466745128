import React from 'react';

import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { HybridDevis } from 'types';

import { CreateBonCommandeModalContent } from '../components/CreateBonCommandeModalContent/CreateBonCommandeModalContent';

type Props = {
  devis: HybridDevis;
  handleClose?: () => void;
};

const CreateBonCommandeModal: React.FC<Props> = props => {
  const openCreateBonCommandeModal = useOpenCreateBonCommandeModal();

  return (
    <CreateBonCommandeModalContent
      {...props}
      openCreateBonCommandeModal={openCreateBonCommandeModal}
    />
  );
};

export const useOpenCreateBonCommandeModal = (): ((props: Props) => void) => {
  const openModal = useOpenModal();

  return (props: Props) =>
    openModal(CreateBonCommandeModal, {
      title: 'Générer un bon de commande',
      closable: true,
      isScrollable: false,
      ...props,
    });
};
