import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { LAST_FACTURES_KEY } from 'features/Deals/features/Factures/api/useLastFacture';
import { Facture } from 'types';
import { FactureTracking } from 'utils/tracking/FactureTracking';
import { getFactureUrl } from 'utils/urls';

import { FACTURES_KEY } from './useFacturesList';

type HandleCreateFactureParameters = {
  facture?: Partial<Facture>;
  preventRedirect?: boolean;
  dealUuid?: string;
};

type HandleCreateFactureReturn = {
  facture: Facture;
  preventRedirect?: boolean;
  fromScratch: boolean;
};

const handleCreateFacture = async ({
  facture,
  preventRedirect,
  dealUuid,
}: HandleCreateFactureParameters): Promise<HandleCreateFactureReturn> => {
  if (!facture && !dealUuid) {
    throw new Error('Incorrect call to createFacture');
  }
  // We pass a facture to duplicate and a dealUuid to create a facture from scratch
  const factureToCreate = facture ? facture : { dealUuid };
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${factureToCreate.dealUuid}/factures`;
  const result = await request(requestURL, {
    method: 'POST',
    body: {
      title: 'Facture',
      lots: [],
      ...factureToCreate,
      montantDejaPaye: 0,
    },
  });
  return {
    fromScratch: !!dealUuid,
    facture: result,
    preventRedirect,
  };
};

export const useCreateFacture = (): typeof handleCreateFacture => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutateAsync: createFacture } = useMutation({
    mutationFn: handleCreateFacture,
    onSuccess: ({ facture, preventRedirect, fromScratch }: HandleCreateFactureReturn) => {
      FactureTracking.onFactureCreated({
        type: fromScratch ? 'Vierge' : 'Duplicate',
        factureToken: facture.token,
        devisToken: facture.proDevisToken,
      });
      if (!preventRedirect) {
        navigate(getFactureUrl(facture));
      }
      queryClient.invalidateQueries({ queryKey: [FACTURES_KEY, facture.dealUuid] });
      queryClient.invalidateQueries({ queryKey: [LAST_FACTURES_KEY] });
    },
    onError: () => {
      toast.error("La facture n'a pas pu être créée");
    },
  });

  return createFacture;
};
