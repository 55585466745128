import React from 'react';

import { subject } from '@casl/ability';
import { useNavigate } from 'react-router';

import DotsMenu from '@travauxlib/shared/src/components/DesignSystem/assets/DotsMenu.svg?react';
import {
  ActionList,
  ActionListItem,
} from '@travauxlib/shared/src/components/DesignSystem/components/ActionList';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { DevisStatuses } from '@travauxlib/shared/src/types';
import { getDetailedDevisFacturationStatus } from '@travauxlib/shared/src/utils/facture/getDetailedDevisFacturationStatus';
import { devisOrProposition } from '@travauxlib/shared/src/utils/wording';

import { useIsArchitecte, useIsHemeaEmployee } from 'api/profileSelectors';
import { useOpenCreateBonCommandeModal } from 'features/BonCommande/hooks/useOpenCreateBonCommandeModal';
import { useDealChantier } from 'features/Chantiers/api/useDealChantier';
import { useDevisSuiviChantier } from 'features/Deals/hooks/useDevisSuiviChantier';
import { useOpenMoveDevisModal } from 'features/Deals/hooks/useOpenMoveDevisModal';
import { useOpenTransferDevisModal } from 'features/Deals/hooks/useOpenTransferDevisModal';
import { duplicateDevis as duplicateDevisObject } from 'features/Deals/utils/devis';
import { useArchiveDevis } from 'features/Devis/api/useArchiveDevis';
import { useCreateDevis } from 'features/Devis/api/useCreateDevis';
import { useCreateSuiviChantier } from 'features/Devis/api/useCreateSuiviChantier';
import { useDuplicateDevis } from 'features/Devis/api/useDuplicateDevis';
import { useUnarchiveDevis } from 'features/Devis/api/useUnarchiveDevis';
import { useOpenFacturerDevisModal } from 'features/Devis/hooks/useOpenFacturerDevisModal';
import { useOpenRelanceModal } from 'features/Devis/hooks/useOpenRelanceModal';
import { useOpenSaveDevisAsTemplateModal } from 'features/Devis/hooks/useOpenSaveDevisAsTemplateModal';
import { useFacturesList } from 'features/Factures/api/useFacturesList';
import { usePermissions } from 'hooks/usePermissions';
import { HybridDevis } from 'types';
import { FactureTracking } from 'utils/tracking/FactureTracking';
import { SuiviDeChantierTracking } from 'utils/tracking/SuiviDeChantierTracking';
import { getFactureSituationUrl, getSuiviChantierRootUrl } from 'utils/urls';

type Props = {
  devis: HybridDevis;
  fixedFloating?: boolean;
};

export const DevisActions: React.FC<Props> = ({ devis, fixedFloating }) => {
  const { facturesList } = useFacturesList(devis.dealUuid);
  const filteredFacturesList = facturesList.filter(
    facture => facture.proDevisToken === devis.token,
  );
  const isHemeaEmployee = useIsHemeaEmployee();
  const openCreateBonCommandeModal = useOpenCreateBonCommandeModal();
  const { createDevis } = useCreateDevis();
  const { duplicateDevis } = useDuplicateDevis();
  const openFacturerDevisModal = useOpenFacturerDevisModal();
  const openSaveDevisAsTemplateModal = useOpenSaveDevisAsTemplateModal();
  const archiveDevis = useArchiveDevis();
  const unarchiveDevis = useUnarchiveDevis();

  const openModalMoveDevis = useOpenMoveDevisModal();
  const openModalTransferDevis = useOpenTransferDevisModal();
  const isArchitecte = useIsArchitecte();
  const openRelanceModal = useOpenRelanceModal();
  const { suiviChantier, isLoading: isLoadingSuiviChantier } = useDevisSuiviChantier(devis);

  const { chantier } = useDealChantier(devis.dealUuid);
  const { protectedNavigate, useProtectedCall } = usePermissions();
  const { createSuiviChantier } = useCreateSuiviChantier();
  const [protectedCreateSuiviDeChantier] = useProtectedCall(
    createSuiviChantier,
    'manage',
    subject('SuiviChantier', { isHemea: devis.isHemea }),
  );
  const [protectedOpenCreateBonCommandeModal] = useProtectedCall(
    openCreateBonCommandeModal,
    'manage',
    subject('BonCommande', { isHemea: devis.isHemea }),
  );

  /* Create title for modals transfer & move devis */
  const moveOrCopy = devis.status === DevisStatuses.draft ? 'Déplacer' : 'Dupliquer';
  const titleModals = `${devisOrProposition({ isArchitecte, prefix: 'le' })} ${devis.title}`;
  const hasGeneratedFactureSituation = facturesList.some(facture => facture.isSituation);
  const navigate = useNavigate();

  const { isClotured } = getDetailedDevisFacturationStatus(filteredFacturesList, devis as any); // probably buggy

  const items: ActionListItem[] = [
    {
      type: 'action',
      label: 'Relancer le devis',
      onClick: () => openRelanceModal(devis),
      disabled: devis.status !== 'sent',
    },
    {
      type: 'action',
      label: 'Dupliquer',
      onClick: () => {
        duplicateDevis({
          devis: devis,
          dealUuid: devis.dealUuid,
        });
      },
      disabled: devis.status === 'signed' && devis.isHemea,
    },
    {
      type: 'action',
      label: `${
        devis.status === DevisStatuses.draft ? 'Déplacer' : 'Dupliquer'
      } vers un autre chantier...`,
      onClick: () => openModalMoveDevis(devis, `${moveOrCopy} ${titleModals}`),
    },
    {
      type: 'action',
      label: 'Remplacer',
      disabled:
        devis.status !== DevisStatuses.signed ||
        !chantier ||
        chantier.transactions?.length > 0 ||
        devis.isTS,
      onClick: () =>
        'lots' in devis &&
        createDevis({
          devis: {
            ...duplicateDevisObject({ ...devis, replacedSignedDevisToken: devis.token }),
            compensationType: undefined,
          },
          dealUuid: devis.dealUuid,
        }),
      hide: !devis.isHemea || !('lots' in devis),
    },
    {
      type: 'action',
      label: 'Sauvegarder comme modèle',
      onClick: () => openSaveDevisAsTemplateModal(devis),
    },
    {
      type: 'action',
      label: "Transférer sur le compte d'un pro",
      onClick: () => openModalTransferDevis(devis, `Transférer ${titleModals}`),
      hide: !isHemeaEmployee,
    },
    {
      type: 'action',
      label: 'archived' in devis && devis.archived ? 'Désarchiver...' : 'Archiver...',
      onClick: () =>
        'archived' in devis && devis.archived ? unarchiveDevis(devis) : archiveDevis(devis),
      disabled: devis.status === DevisStatuses.signed,
    },
    {
      type: 'action',
      label: 'Télécharger en PDF',
      onClick: () =>
        window.open(
          'hasSignedDevisPdf' in devis && devis.hasSignedDevisPdf
            ? `${APP_CONFIG.apiURL}/devis-pro/${devis.token}/signed-devis-pdf`
            : `${APP_CONFIG.apiURL}/devis-pro/${devis.token}/pdf`,
        ),
    },
    {
      type: 'divider',
      label: 'first-divider',
    },
    {
      type: 'action',
      label: 'Facturer le devis',
      onClick: () => {
        if (hasGeneratedFactureSituation) {
          navigate(getFactureSituationUrl(devis));
        } else {
          openFacturerDevisModal(devis);
        }
        FactureTracking.onFactureModalOpened({ source: 'Bouton Facturer le devis...' });
      },
      disabled: !devis.signe || isClotured,
    },
    {
      type: 'action',
      label: 'Voir les factures',
      onClick: () => navigate(`/deals/${devis.dealUuid}/factures`),
      disabled: facturesList.every(facture => facture.proDevisToken !== devis.token),
    },
    {
      type: 'divider',
      label: 'second-divider',
    },
    {
      type: 'action',
      label: 'Accéder aux modalités hemea',
      onClick: () => window.open(`${APP_CONFIG.adminURL}/modalites/devis/${devis.token}`),
      hide: !isHemeaEmployee || !devis.isHemea,
    },
    {
      type: 'action',
      label: 'Voir le suivi de chantier',
      onClick: () => {
        SuiviDeChantierTracking.onSuiviDeChantierOpened({ uuid: suiviChantier!.uuid });
        protectedNavigate(
          getSuiviChantierRootUrl(suiviChantier!),
          'manage',
          subject('SuiviChantier', { isHemea: devis.isHemea }),
        );
      },
      disabled: !suiviChantier,
    },
    {
      type: 'action',
      label: 'Générer le suivi de chantier',
      onClick: () => protectedCreateSuiviDeChantier(devis),
      disabled: !devis.signe || isLoadingSuiviChantier || !!suiviChantier,
    },
    {
      type: 'action',
      label: 'Générer un bon de commande',
      onClick: () => protectedOpenCreateBonCommandeModal({ devis: devis }),
      disabled: !devis.signe,
    },
  ];

  return (
    <ActionList
      trigger={(toggle, referenceProps) => (
        <IconButton
          {...referenceProps}
          className="ml-sm"
          size="md"
          onClick={toggle}
          data-testid="open-devis-actions"
        >
          <DotsMenu />
        </IconButton>
      )}
      fixedFloating={fixedFloating}
      position="left"
      small
      items={items}
    />
  );
};
