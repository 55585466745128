import { UseMutateAsyncFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { ProDevisView } from '@travauxlib/shared/src/types/api/pro/ProDevisView';
import { request } from '@travauxlib/shared/src/utils/request';

import { fadingTime } from 'features/Deals/utils/constants';
import { DevisTracking } from 'utils/tracking/DevisTracking';

import { DEVIS_LIST_KEY } from './useDevisList';

export type CreateProDevisView = Omit<Partial<ProDevisView>, 'compensationType'> & {
  compensationType?: string;
}; // oulah pas beau mais pas le choix si on veut éviter la turbo pr

export type CreatePayload = {
  dealUuid: string;
  devis: CreateProDevisView;
};

const handleCreateDevis = ({ devis, dealUuid }: CreatePayload): Promise<ProDevisView> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/devis`;
  return request(requestURL, {
    method: 'POST',
    body: devis,
  });
};

export const useCreateDevis = ({
  redirectFc,
}: { redirectFc?: (devis: ProDevisView) => string } = {}): {
  createDevis: UseMutateAsyncFunction<ProDevisView, Error, CreatePayload>;
  isLoading: boolean;
} => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutateAsync: createDevis, isPending } = useMutation({
    mutationFn: handleCreateDevis,
    onSuccess: (devis: ProDevisView) => {
      DevisTracking.onDevisCreated({
        type: devis.isRGE ? 'RGE' : devis.isTS ? 'TS' : 'Classic',
        devisToken: devis.token,
      });
      queryClient.setQueryData(
        [DEVIS_LIST_KEY, devis.dealUuid],
        (devisListCached: ProDevisView[] = []) => [
          ...devisListCached,
          { ...devis, isHighlighted: true },
        ],
      );
      // We set a timeout to avoid the blink of animation if you switch tabs
      setTimeout(
        () => queryClient.refetchQueries({ queryKey: [DEVIS_LIST_KEY, devis.dealUuid] }),
        fadingTime,
      );
      if (redirectFc) {
        navigate(redirectFc(devis));
      }
    },
    onError: () => {
      toast.error("Le devis n'a pas pu être créé");
    },
  });
  return { createDevis, isLoading: isPending };
};
