import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { BonCommande } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { BON_COMMANDE_KEY } from 'features/BonCommande/api/useGetBonCommande';
import { BonDeCommandeTracking } from 'utils/tracking/BonDeCommandeTracking';
import { getBonCommandeRootUrl } from 'utils/urls';

const handleDuplicateBonCommande = (bonCommande: BonCommande): Promise<BonCommande> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${bonCommande.dealUuid}/bon-commande/${bonCommande.token}/duplicate`;
  return request(requestURL, { method: 'POST', body: { ...bonCommande } });
};

export const useDuplicateBonCommande = (): {
  duplicateBonCommande: typeof handleDuplicateBonCommande;
  isLoading: boolean;
} => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutateAsync: duplicateBonCommande, isPending } = useMutation({
    mutationFn: handleDuplicateBonCommande,
    onSuccess: (bonCommande: BonCommande) => {
      BonDeCommandeTracking.onBonDeCommandeCreated({
        uuid: bonCommande.uuid,
        type: 'Duplication',
      });
      queryClient.invalidateQueries({ queryKey: [BON_COMMANDE_KEY, bonCommande.token] });
      navigate(getBonCommandeRootUrl(bonCommande));
    },
    onError: () => {
      toast.error("Le bon de commande n'a pas pu être généré");
    },
  });
  return { duplicateBonCommande, isLoading: isPending };
};
