import { useQuery } from '@tanstack/react-query';

import { ProUserProfileView } from '@travauxlib/shared/src/types/api/common/ProUserProfileView';
import { request } from '@travauxlib/shared/src/utils/request';

import { defineAbilityFor } from 'lib/ability/defineAbilityFor';
import { AppAbility } from 'lib/ability/entities/Ability';

export const AUTH_KEY = 'auth';

export const useProfile = (): {
  ability: AppAbility;
  isLoading: boolean;
  isAuthenticated: boolean;
  profile: ProUserProfileView;
} => {
  const { data: profile, isLoading } = useQuery<ProUserProfileView>({
    queryKey: [AUTH_KEY],
    queryFn: () => {
      const requestURL = `${APP_CONFIG.apiURL}/pro/users/profile`;
      return request(requestURL);
    },
    // 1 min
    staleTime: 60000,
    refetchOnWindowFocus: 'always',
  });

  const ability = defineAbilityFor(profile?.company, profile?.isHemeaEmployee);

  return {
    ability,
    isLoading,
    isAuthenticated: profile?.uuid !== undefined,
    profile: profile!,
  };
};
