import { ProUserProfileView } from '@travauxlib/shared/src/types/api/common/ProUserProfileView';
import { ProCompanyUserView } from '@travauxlib/shared/src/types/api/pro/ProCompanyUserView';

import { useGetProCompanyUsers } from 'api/useGetProCompanyUsers';
import { useProfile } from 'api/useProfile';

export const useMultiUser = (): {
  profile: ProUserProfileView;
  proUsers: ProCompanyUserView[];
  hasMultiUsersAccess: boolean;
  isLoading: boolean;
} => {
  const { profile, isLoading: profileIsLoading } = useProfile();
  const { proUsers, isLoading: proUsersIsLoading } = useGetProCompanyUsers();
  const hasMultiUsersAccess = !!(profile?.isProCompanyAdmin && proUsers.length > 1);

  return {
    profile,
    proUsers,
    hasMultiUsersAccess,
    isLoading: profileIsLoading || proUsersIsLoading,
  };
};
