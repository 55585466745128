import React from 'react';

import { useNavigate } from 'react-router';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';

import { dashboardUrl } from 'utils/urls';

export const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div id="content-404" className="!flex !justify-center">
      <div className="!text-center">
        <h1>Oops!</h1>
        <h2>Cette page est introuvable</h2>
        <div className="!mb-xmd">
          La page à laquelle vous essayez d&apos;accéder n&apos;existe pas.
        </div>
        <div>
          <Button onClick={() => navigate(dashboardUrl)}>Revenir à l&apos;accueil</Button>
        </div>
      </div>
    </div>
  );
};
