import dayjs from 'dayjs';

import { ProDevisView } from '@travauxlib/shared/src/types/api/pro/ProDevisView';

export const wasAtLeastOneDevisCreatedBeforeDate = (
  devisList: ProDevisView[],
  date: string | undefined,
): boolean => {
  if (!date) {
    return false;
  }

  return devisList.some(devis => dayjs(devis.createdAt).diff(dayjs(date)) < 0);
};
