import React from 'react';

import dayjs from 'dayjs';

import CalendarFilled from '@travauxlib/shared/src/components/DesignSystem/assets/CalendarFilled.svg?react';
import EditFilled from '@travauxlib/shared/src/components/DesignSystem/assets/EditFilled.svg?react';
import EnvelopeFilled from '@travauxlib/shared/src/components/DesignSystem/assets/EnvelopeFilled.svg?react';
import LocationFilled from '@travauxlib/shared/src/components/DesignSystem/assets/LocationFilled.svg?react';
import PhoneFilled from '@travauxlib/shared/src/components/DesignSystem/assets/PhoneFilled.svg?react';
import { ProCompanyUserView } from '@travauxlib/shared/src/types/api/pro/ProCompanyUserView';
import { shortDateTimeFormat } from '@travauxlib/shared/src/utils/time';

import { HybridDeal } from 'types';

import { useOpenRDVModal } from '../../hooks/useOpenRDVModal';

type Props = {
  clientEmail?: string;
  clientPhoneNumber?: string;
  clientFormattedAddress?: string;
  deal?: HybridDeal;
  proUser?: ProCompanyUserView;
};

export const ProClientInfo: React.FC<Props> = ({
  clientEmail,
  clientPhoneNumber,
  clientFormattedAddress,
  deal,
}) => {
  const openRDVModal = useOpenRDVModal();

  return (
    <>
      {clientFormattedAddress && (
        <div className="!flex !items-center !mb-xs text-sm">
          <LocationFilled className="w-sm h-sm mr-sm" /> {clientFormattedAddress}
        </div>
      )}
      {clientPhoneNumber && (
        <div className="!flex !items-center !mb-xs text-sm">
          <PhoneFilled className="w-sm h-sm mr-sm" /> {clientPhoneNumber}
        </div>
      )}
      {clientEmail && (
        <div className="!flex !items-center !mb-xs text-sm">
          <EnvelopeFilled className="w-sm h-sm mr-sm" /> {clientEmail}
        </div>
      )}
      {deal && 'meeting' in deal && deal?.meeting && !deal.meeting.hasOccurred && (
        <div className="!flex !items-center !mb-xs !text-sm">
          <CalendarFilled className="w-sm h-sm mr-sm" />
          <span className="!mr-sm">
            Rendez-vous le {shortDateTimeFormat(dayjs(deal.meeting.startAt))}
          </span>
          <EditFilled className="w-sm h-sm cursor-pointer" onClick={() => openRDVModal({ deal })} />
        </div>
      )}
    </>
  );
};
