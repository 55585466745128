import React from 'react';

import { Link as DSLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { ProDevisView } from '@travauxlib/shared/src/types/api/pro/ProDevisView';

import { Link } from 'components/Link';
import { getDevisUrl } from 'utils/urls';

type Props = {
  devis: ProDevisView;
  label: string;
};

export const DevisLink: React.FC<Props> = ({ devis, label }) =>
  ['sent', 'signed'].includes(devis.status) ? (
    <DSLink
      href={`${APP_CONFIG.appURL}/pro/devis/${devis.token}?isAuthor`}
      target="_blank"
      rel="noreferrer noopener"
    >
      {label}
    </DSLink>
  ) : (
    <Link to={getDevisUrl(devis)}>{label}</Link>
  );
