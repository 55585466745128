import { useQuery, useQueryClient } from '@tanstack/react-query';

import { SimpleProClientView } from '@travauxlib/shared/src/types/api/admin/SimpleProClientView';
import { request } from '@travauxlib/shared/src/utils/request';

import { TRANSFER_DEVIS_KEY } from './useProCompanyDevisList';

export const TRANSFER_PRO_CLIENTS_KEY = 'transfer-pro-clients';

export const useProCompanyProClients = (
  proCompanySlug: string,
): {
  proClients: SimpleProClientView[];
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery<{ data: SimpleProClientView[] }>({
    queryKey: [TRANSFER_PRO_CLIENTS_KEY, proCompanySlug],
    queryFn: async () => {
      const result = await request(
        `${APP_CONFIG.apiURL}/admin/pro-companies/${proCompanySlug}/clients`,
      );
      queryClient.invalidateQueries({ queryKey: [TRANSFER_DEVIS_KEY] });
      return result;
    },
  });

  const proClients = data?.data || [];

  return {
    proClients,
    isLoading,
  };
};
