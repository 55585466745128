import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { LAST_DEVIS_KEY } from 'features/Deals/features/Devis/api/useLastDevis';
import { HybridDevis } from 'types';

import { DEVIS_LIST_KEY } from './useDevisList';

const handleArchive = (devis: HybridDevis): Promise<HybridDevis> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${devis.dealUuid}/devis/${devis.token}`;
  return request(requestURL, {
    method: 'DELETE',
  });
};

export const useArchiveDevis = (): typeof handleArchive => {
  const queryClient = useQueryClient();

  const { mutateAsync: archive } = useMutation({
    mutationFn: handleArchive,
    onSuccess: (devis: HybridDevis) => {
      toast.success('Le devis a bien été archivé');
      queryClient.invalidateQueries({ queryKey: [DEVIS_LIST_KEY, devis.dealUuid] });
      queryClient.invalidateQueries({ queryKey: [LAST_DEVIS_KEY] });
    },
    onError: () => {
      toast.error("Le devis n'a pas pu être archivé");
    },
  });
  return archive;
};
