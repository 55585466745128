import React from 'react';

import classNames from 'classnames';

import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { ProDevisFactureLigneView } from '@travauxlib/shared/src/types/api/common/ProDevisFactureLotView';

type Props = {
  ligne: ProDevisFactureLigneView;
  index: string;
  onChange: () => void;
  checked: boolean;
};

export const CheckboxLigneCard: React.FC<Props> = ({ ligne, index, onChange, checked }) => (
  <div
    className={classNames('py-sm pl-xs pr-md border rounded-xxs flex items-center group', {
      'border-primary': checked,
    })}
  >
    <Checkbox
      className="mr-xs"
      id={ligne.uuid}
      name={ligne.uuid}
      checked={checked}
      onChange={onChange}
    />
    <label
      className="flex items-center gap-xs grow overflow-auto mb-0 cursor-pointer"
      htmlFor={ligne.uuid}
    >
      <div className="text-neutral-600">{index}</div>
      <div className="grow truncate">{ligne.designation}</div>
      <EURCurrency amount={ligne.prixHT} />
    </label>
  </div>
);
