import React from 'react';

import { Form } from 'react-final-form';

import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { required } from '@travauxlib/shared/src/utils/form';
import { devisOrProposition } from '@travauxlib/shared/src/utils/wording';

import { useProCompany } from 'api/profileSelectors';
import { ProClientAndDealSelector } from 'components/ProClientAndDealSelector';
import { useDeal } from 'features/Deals/api/useDeal';
import { ExternalProCompany, HybridDevis } from 'types';

import { useProCompanies } from './api/useProCompanies';
import { useProCompanyDevisList } from './api/useProCompanyDevisList';
import { useProCompanyProClients } from './api/useProCompanyProClients';
import { useTransferToPro } from './api/useTransferToPro';

type FormValues = {
  newDealUuid: string;
  proClientUuid: string;
  newProjectUuid?: string;
  newProCompanySlug: string;
  shouldResetPrices: boolean;
  isPreChiffrage: boolean;
  shouldTransferIntroductionLetter: boolean;
};

type FormContentProps = {
  handleSubmit: (e: React.FormEvent) => void;
  values: FormValues;
  isArchitecte: boolean;
  submitting: boolean;
  proCompanies: ExternalProCompany[];
};

const FormContent: React.FC<FormContentProps> = ({
  handleSubmit,
  values,
  submitting,
  isArchitecte,
  proCompanies,
}) => {
  const { newProCompanySlug: proCompanySlug, newDealUuid: dealUuid, proClientUuid } = values;

  const { proClients, isLoading } = useProCompanyProClients(proCompanySlug);
  const deals = proClients.find(proClient => proClient.uuid === proClientUuid)?.deals || [];
  const { devisList } = useProCompanyDevisList({
    proCompanySlug,
    dealUuid,
  });

  return (
    <ModalContent
      handleSubmit={handleSubmit}
      validateAction={{
        label: ` Transférer ${devisOrProposition({ isArchitecte, prefix: 'le' })}`,
        type: 'submit',
        disabled: submitting,
      }}
    >
      <div className="mb-md">
        <DropdownField
          name="newProCompanySlug"
          id="new-pro-company-slug"
          label="Entreprise"
          options={proCompanies.map(proCompany => ({
            value: proCompany.slug,
            label: proCompany.name,
          }))}
          validate={required}
          fixedFloating={false}
        />
      </div>
      <CheckboxField
        name="shouldResetPrices"
        id="should-reset-prices"
        label="Mettre les prix unitaires à 0"
        className="!flex !mb-xs"
      />
      <CheckboxField
        name="shouldTransferIntroductionLetter"
        id="shouldTransferIntroductionLetter"
        label="Transférer le mot d'introduction"
        className="!flex !mb-xs"
      />
      <CheckboxField
        name="isPreChiffrage"
        id="is-pre-chiffrage"
        label="Afficher pré-chiffrage dans le devis"
        className="!flex !mb-xs"
      />
      {isLoading ? (
        <Loader />
      ) : (
        <ProClientAndDealSelector
          proClients={proClients}
          deals={deals}
          devisList={devisList}
          label="Transférer vers le client"
        />
      )}
    </ModalContent>
  );
};

type Props = {
  isArchitecte: boolean;
  devisToTransfer: HybridDevis;
  onSubmitSuccess: () => void;
};

export const TransferDevisForm: React.FC<Props> = ({
  isArchitecte,
  devisToTransfer,
  onSubmitSuccess,
}) => {
  const currentProCompanySlug = useProCompany()?.slug;
  const { transferToPro } = useTransferToPro(devisToTransfer);
  const proCompanies = useProCompanies();
  const deal = useDeal(devisToTransfer.dealUuid);

  return (
    <Form<FormValues>
      initialValuesEqual={() => true}
      initialValues={{
        proClientUuid: deal?.proClientUuid,
        newDealUuid: devisToTransfer.dealUuid,
        newProCompanySlug: currentProCompanySlug,
        isPreChiffrage: false,
        shouldTransferIntroductionLetter: false,
        shouldResetPrices: false,
      }}
      onSubmit={async values => {
        await transferToPro({
          ...values,
          newProjectUuid: values.newProjectUuid || undefined,
        });

        return onSubmitSuccess();
      }}
    >
      {({ handleSubmit, submitting, values }) => (
        <FormContent
          handleSubmit={handleSubmit}
          submitting={submitting}
          values={values}
          isArchitecte={isArchitecte}
          proCompanies={proCompanies}
        />
      )}
    </Form>
  );
};
