import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { BonCommande } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { HybridDevis } from 'types';
import { BonDeCommandeTracking } from 'utils/tracking/BonDeCommandeTracking';
import { getBonCommandeRootUrl } from 'utils/urls';

type Payload = {
  devis: HybridDevis;
  marge: number;
  contractorUuid: string;
};

const handleCreateBonCommande = ({
  devis,
  marge,
  contractorUuid,
}: Payload): Promise<BonCommande> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${devis.dealUuid}/devis/${devis.token}/bon-commande`;
  return request(requestURL, { method: 'POST', body: { marge, contractorUuid } });
};

export const useCreateBonCommande = (): {
  createBonCommande: typeof handleCreateBonCommande;
  isLoading: boolean;
} => {
  const navigate = useNavigate();
  const { mutateAsync: createBonCommande, isPending } = useMutation({
    mutationFn: handleCreateBonCommande,
    onSuccess: (bonCommande: BonCommande) => {
      BonDeCommandeTracking.onBonDeCommandeCreated({ uuid: bonCommande.uuid, type: 'Vierge' });
      navigate(getBonCommandeRootUrl(bonCommande));
    },
    onError: () => {
      toast.error("Le bon de commande n'a pas pu être généré");
    },
  });
  return { createBonCommande, isLoading: isPending };
};
