import React, { useState } from 'react';

import { ArchivedToggle } from '@travauxlib/shared/src/components/ArchivedToggle';
import PaperSearch from '@travauxlib/shared/src/components/DesignSystem/assets/PaperSearch.svg?react';
import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbolFilled.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';

import { FacturesBlock } from 'features/Deals/features/Factures/components/FacturesBlock/FactureBlock';
import { useHandleClickCreateNewFacture } from 'features/Deals/features/Factures/hooks/useHandleClickCreateNewFacture';
import { useDevisList } from 'features/Devis/api/useDevisList';
import { useFacturesList } from 'features/Factures/api/useFacturesList';

import { generateFacturesListByDevis } from './utils/generateFacturesListByDevis';

type Props = {
  dealUuid: string;
};

export const Factures: React.FC<Props> = ({ dealUuid }) => {
  const [showArchived, setShowArchived] = useState(false);
  const { devisList, isLoaded: devisListIsLoaded } = useDevisList({
    dealUuid,
    showArchived: false,
    showOnlySigned: true,
  });

  const { facturesList, isLoaded } = useFacturesList(dealUuid);
  const handleClickCreateNewFacture = useHandleClickCreateNewFacture(dealUuid);
  const filteredFactureList = facturesList.filter(({ archived }) => archived === showArchived);

  if (!isLoaded && !devisListIsLoaded) {
    return <Loader />;
  }

  // On utilise ici la liste avec toutes les factures pour être capable de calculer
  // l'état de facturation meme lorsqu'on affiche les factures archivées
  const facturesByDevis = generateFacturesListByDevis(devisList, facturesList);

  return (
    <div className="py-xl sm-desktop:pb-xs">
      <div className="flex justify-between items-center mb-xl">
        <ArchivedToggle
          id="facture"
          showArchived={showArchived}
          setShowArchived={setShowArchived}
        />
        <Button
          className="ml-md shrink-0"
          size="md"
          leftIcon={<PlusSymbol />}
          onClick={handleClickCreateNewFacture.bind(null, 'Bouton nouvelle facture')}
        >
          Nouvelle facture
        </Button>
      </div>
      {(devisList.length === 0 || showArchived) && filteredFactureList.length === 0 ? (
        <EmptyState
          icon={<PaperSearch />}
          title={
            showArchived ? 'Aucune facture archivée' : 'Aucun devis signé ou aucune facture trouvée'
          }
          description={
            showArchived
              ? 'Vous trouverez ici les factures que vous avez archivées'
              : 'Passer un devis en signé pour commencer sa facturation ou créez une facture indépendante'
          }
          primaryAction={
            showArchived
              ? undefined
              : {
                  label: 'Créer une facture',
                  onClick: handleClickCreateNewFacture.bind(
                    null,
                    'Bouton Créer une nouvelle facture',
                  ),
                }
          }
        />
      ) : (
        facturesByDevis.map(({ devis, factures }) => (
          <FacturesBlock
            key={devis?.token || 'standalone'}
            facturesList={factures}
            devis={devis}
            dealUuid={dealUuid}
            isArchived={showArchived}
          />
        ))
      )}
    </div>
  );
};
