import React from 'react';

import { ChipPictoVerticalListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { ProDevisView } from '@travauxlib/shared/src/types/api/pro/ProDevisView';
import { required } from '@travauxlib/shared/src/utils/form';

import { useGetFacturationMethodOptions } from 'features/Devis/components/FacturerDevisModal/hooks/useGetFacturationMethodOptions';

type Props = {
  devisSelected: ProDevisView;
};

export const ChoiceFactureFromDevis: React.FC<Props> = ({ devisSelected }) => {
  const options = useGetFacturationMethodOptions(devisSelected);

  return (
    <>
      <p className="text-b1 mb-md text-neutral-600">
        Veuillez sélectionner une méthode de facturation
      </p>
      <ChipPictoVerticalListField
        id="selected-payment-type"
        name="selectedPaymentType"
        options={options}
        size="sm"
        className="flex gap-md"
        validate={required}
      />
    </>
  );
};
