import { useNavigate } from 'react-router';

import { Facture } from 'types';
import { getFactureUrl } from 'utils/urls';

type HandleClickItem = (facture: Facture) => void | Window | null;

export const useNavigateToFacture = (): HandleClickItem => {
  const navigate = useNavigate();

  const handleClickItem = (facture: Facture): void => {
    const link = getFactureUrl(facture);
    const externalLink = facture.status !== 'draft' || facture.archived;
    if (externalLink) {
      window.open(link);
    } else {
      navigate(link);
    }
  };

  return handleClickItem;
};
