import React, { FC, useCallback } from 'react';

import { DealView } from '@travauxlib/shared/src/types/api/pro/DealView';

import { useProfile } from 'api/useProfile';
import { ParticipantList } from 'features/Deals/components/ParticipantHandler/ParticipantList';
import { useOpenConversationInfoModal } from 'features/Deals/hooks/useOpenConversationInformationModal';
import { ConversationParticipant } from 'types';

import { RightColumnSectionTitle } from './RightColumnSectionTitle';
import { RightColumnTab } from './RightColumnTab';

type RightColumnProps = {
  deal: DealView;
};

export const RightColumn: FC<RightColumnProps> = ({ deal }) => {
  const { profile } = useProfile();
  const openConversationInformationModal = useOpenConversationInfoModal();

  const onParticipantClick = useCallback(
    (participant?: ConversationParticipant) => {
      openConversationInformationModal({
        dealUuid: deal.uuid,
        profile,
        activeTab: {
          category: 'participants',
          participant,
          mode: participant?.uuid ? 'edit' : 'consult',
        },
      });
    },
    [deal, profile],
  );

  const onPlusClick = useCallback(() => {
    openConversationInformationModal({
      dealUuid: deal.uuid,
      profile,
      activeTab: {
        category: 'participants',
        mode: 'add',
      },
    });
  }, [deal, profile]);

  return (
    <div className="!flex-col flex-grow !shrink relative w-full px-md sm-desktop:max-w-4/12 !px-0 !max-h-full hidden sm-desktop:flex sm-desktop:border-l">
      <RightColumnSectionTitle onClick={onParticipantClick} text="Participants" />
      <div className="!border-b !text-primary ">
        <ParticipantList
          profile={profile}
          deal={deal}
          onPlusClick={onPlusClick}
          onParticipantClick={onParticipantClick}
        />
      </div>
      <RightColumnTab deal={deal} />
    </div>
  );
};
