import React from 'react';

import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { LotIcon } from '@travauxlib/shared/src/features/DevisDisplay/components/LotIcon';
import {
  ProDevisFactureLotView,
  ProDevisFactureLigneView,
} from '@travauxlib/shared/src/types/api/common/ProDevisFactureLotView';

type Props = {
  lot: ProDevisFactureLotView;
  indexLabel: string;
  lignesToDisplay: ProDevisFactureLigneView[];
  onChange: (checkedLigneUuids: string[]) => void;
  checkedLigneUuids: string[];
};

export const CheckboxLotCard: React.FC<Props> = ({
  lot,
  indexLabel,
  lignesToDisplay,
  onChange,
  checkedLigneUuids,
}) => {
  const isAllLignesSelected = lot.items.every(ligne => checkedLigneUuids.includes(ligne.uuid));
  const isSomeLignesSelected = lot.items.some(ligne => checkedLigneUuids.includes(ligne.uuid));

  return (
    <div className="flex items-start group px-xs py-sm">
      <Checkbox
        id={lot.uuid}
        onChange={() => {
          const lignesToDisplayUuids = lignesToDisplay.map(ligne => ligne.uuid);
          const ligneToAdd = lignesToDisplay.filter(
            ligne => !checkedLigneUuids.includes(ligne.uuid),
          );

          if (ligneToAdd.length > 0) {
            onChange([...checkedLigneUuids, ...ligneToAdd.map(ligne => ligne.uuid)]);
          } else {
            onChange(
              checkedLigneUuids.filter(ligneUuid => !lignesToDisplayUuids.includes(ligneUuid)),
            );
          }
        }}
        checked={isAllLignesSelected}
        indeterminate={isSomeLignesSelected && !isAllLignesSelected}
      />
      <label className="flex items-start cursor-pointer grow mb-xs" htmlFor={lot.uuid}>
        <div className="text-neutral-600 mx-xs">{indexLabel}.0</div>
        <div className="flex flex-col grow">
          <div className="flex items-center mb-xs font-bold">
            <LotIcon lotLabel={lot.label} className="mr-xxs w-lg h-lg" /> {lot.label}
          </div>
          <div className="flex justify-between text-b2">
            Total de la prestation <EURCurrency className="font-bold" amount={lot.prixTotalHT} />
          </div>
        </div>
      </label>
    </div>
  );
};
