import React, { useCallback, useMemo, useState } from 'react';

import _sum from 'lodash/sum';
import _uniqBy from 'lodash/uniqBy';
import { v4 as uuidV4 } from 'uuid';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import {
  ModalContent,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { LigneStatus } from '@travauxlib/shared/src/types';
import {
  ProDevisFactureLotView,
  ProDevisFactureLigneView,
} from '@travauxlib/shared/src/types/api/common/ProDevisFactureLotView';
import { ProDevisView } from '@travauxlib/shared/src/types/api/pro/ProDevisView';

import { useDevisList } from 'features/Devis/api/useDevisList';

import { useSearchDevisLignes } from './useSearchDevisLignes';

import { CreateTSDevisModalContent } from '../components/CreateTSDevisModal/CreateTSDevisModalContent';
import { mergeLots } from '../utils/mergeLots';

type OwnProps = {
  currentDevisTS?: ProDevisView;
};

type Props = OwnProps & {
  handleClose: () => void;
  isArchitecte?: boolean;
  dealUuid: string;
};

export const CreateTSDevisModal: React.FC<Props> = ({
  dealUuid,
  currentDevisTS,
  isArchitecte,
  handleClose,
}) => {
  const { isLoaded, devisList } = useDevisList({ dealUuid });
  const [search, setSearch] = useState<string>('');

  const locations = _uniqBy(
    devisList.flatMap(devis =>
      devis.status === 'signed' && devis.locations ? devis.locations : [],
    ),
    'uuid',
  );
  const lots = useMemo(() => {
    const signedDevisList = devisList.filter(devis => devis.status === 'signed');
    const ligneUuidsToExclude = signedDevisList
      .flatMap(devis => devis.lots.flatMap(lot => lot.items))
      .filter(ligne => ligne.type === 'ligne' && !!ligne.linkedToDevisItemUuid)
      .map((ligne: ProDevisFactureLigneView) => ligne.linkedToDevisItemUuid);

    return mergeLots(signedDevisList.flatMap(devis => devis.lots)).map(lot => {
      const filteredItems = lot.items.filter(
        ligne =>
          ligne.type === 'ligne' &&
          !ligne.status &&
          !ligneUuidsToExclude.includes(ligne.uuid) &&
          ligne.prixTTC > 0,
      ) as ProDevisFactureLigneView[];

      return {
        ...lot,
        prixTotalHT: _sum(filteredItems.map(ligne => ligne.prixHT)),
        items: filteredItems,
      };
    });
  }, [devisList]);

  const lignes = useMemo(
    () =>
      lots.flatMap((lot: ProDevisFactureLotView) =>
        lot.items.map((item: ProDevisFactureLigneView) => ({ ...item, lotLabel: lot.label })),
      ),
    [lots],
  );

  const resultLignes = useSearchDevisLignes(lignes, search);

  const ligneUuidsToDisplay = resultLignes.map(ligne => ligne.uuid);
  const lotUuidsToDisplayAtLeastOneLigne = resultLignes.map(ligne => ligne.lotUuid!);

  const shouldDisplayLigne = useCallback(
    (ligne: ProDevisFactureLigneView) => ligneUuidsToDisplay.includes(ligne.uuid),
    [ligneUuidsToDisplay],
  );

  const getLotFromCheckedLignes = useCallback(
    (checkedLigneUuids: string[]) =>
      lots
        .map((lot: ProDevisFactureLotView) => {
          const lotUuid = uuidV4();

          return {
            ...lot,
            uuid: lotUuid,
            items: lot.items
              .filter(ligne => checkedLigneUuids.includes(ligne.uuid))
              .map(ligne => ({
                ...ligne,
                uuid: uuidV4(),
                lotUuid: lotUuid,
                status: LigneStatus.ToEdit,
                linkedToDevisItemUuid: ligne.uuid,
              })),
          };
        })
        .filter((lot: ProDevisFactureLotView) => lot.items.length > 0),
    [lots],
  );

  if (!isLoaded) {
    return (
      <ModalContent>
        <Loader />
      </ModalContent>
    );
  }

  const lotsToDisplay = lots
    .map((lot, index: number) => ({ ...lot, index }))
    .filter(lot => lotUuidsToDisplayAtLeastOneLigne.includes(lot.uuid));

  return (
    <CreateTSDevisModalContent
      isArchitecte={isArchitecte}
      dealUuid={dealUuid}
      search={search}
      setSearch={setSearch}
      lotsToDisplay={lotsToDisplay}
      shouldDisplayLigne={shouldDisplayLigne}
      getLotFromCheckedLignes={getLotFromCheckedLignes}
      handleClose={handleClose}
      currentDevisTS={currentDevisTS}
      locations={locations}
    />
  );
};

export const useOpenCreateTSDevisModal = (
  isArchitecte: boolean,
  dealUuid: string,
): ((props: OwnProps) => void) => {
  const openModal = useOpenModal();

  return (props: OwnProps) =>
    openModal(CreateTSDevisModal, {
      title: 'Sélectionner les prestations à supprimer ou modifier',
      closable: true,
      disableShadow: 'top',
      isArchitecte,
      dealUuid,
      ...props,
    });
};
