import React from 'react';

import EyeClose from '@travauxlib/shared/src/components/DesignSystem/assets/EyeCloseFilled.svg?react';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

export type AccountCardStatus = 'neutral' | 'complete' | 'uncomplete' | 'alert';

const StatusColors: {
  [K in AccountCardStatus]: { bg: string; label: string };
} = {
  neutral: { bg: '!bg-white', label: '!text-info' },
  complete: { bg: '!bg-success-100', label: '!text-success' },
  uncomplete: { bg: '!bg-primarylighter', label: 'text-primary' },
  alert: { bg: '!bg-error-50', label: '!text-info' },
};

export type AccountCardDetailsItem = {
  supLabel: string;
  amount: number;
  subAmount?: number;
  subSuffix?: string;
  subOnClick?: () => void;
};

type Props = {
  ibanComponent?: React.ReactElement;
  buttonComponent?: React.ReactElement;
  title: string;
  details: AccountCardDetailsItem[];
  status: AccountCardStatus;
  showAmounts?: boolean;
};

export const AccountCard: React.FC<Props> = ({
  ibanComponent,
  buttonComponent,
  title,
  details,
  status,
  showAmounts = true,
}) => (
  <div
    className={`rounded-md !border !shadow-xl !p-md tablet:!p-lg ${StatusColors[status].bg}`}
    data-testid="card"
  >
    {ibanComponent}
    <div>
      {buttonComponent && <div className="!float-right">{buttonComponent}</div>}
      <div className="!mb-lg font-bold text-h5">{title}</div>
    </div>
    <div className="flex justify-between flex-wrap gap-x-md gap-y-xl">
      {details.map(item => (
        <div key={item.supLabel}>
          <>
            <span className="whitespace-nowrap">{item.supLabel}</span>
            <div className="text-h3">
              {showAmounts ? (
                <EURCurrency
                  amount={item.amount}
                  className={`!font-bold ${
                    StatusColors[
                      status === 'uncomplete' && item.subAmount === 0 ? 'complete' : status
                    ].label
                  }`}
                  forceFullPattern
                />
              ) : (
                <EyeClose className="w-md h-md text-danger" />
              )}
            </div>
            {item.subOnClick ? (
              <button
                type="button"
                className="hover:underline text-primary bg-transparent !p-0 !m-0 !border-0"
                onClick={item.subOnClick}
              >
                {item.subSuffix}
              </button>
            ) : (
              <>
                {item.subAmount !== undefined && (
                  <>
                    <EURCurrency amount={item.subAmount} forceFullPattern /> {item.subSuffix}
                  </>
                )}
              </>
            )}
          </>
        </div>
      ))}
    </div>
  </div>
);
