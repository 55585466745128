import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { DevisStatuses } from '@travauxlib/shared/src/types';
import { ProDevisView } from '@travauxlib/shared/src/types/api/pro/ProDevisView';
import { devisOrProposition } from '@travauxlib/shared/src/utils/wording';

import { useProCompany } from 'api/profileSelectors';
import { useDeal } from 'features/Deals/api/useDeal';
import { getEmptyDevis } from 'features/Deals/utils/devis';
import { useCreateDevis } from 'features/Devis/api/useCreateDevis';
import { AddAttachmentModal } from 'features/Devis/components/AddAttachmentModal';
import { getIsFirstDevisSignedImported } from 'utils/getIsFirstDevisSignedImported';

import { useDevisList } from '../api/useDevisList';

export const useUploadDevisModal = ({
  dealUuid,
  projectUuid,
}: {
  dealUuid: string;
  projectUuid?: string;
}): { handleOpenModal: () => void; disabled: boolean } => {
  const company = useProCompany();
  const openModal = useOpenModal();

  const deal = useDeal(dealUuid)!;

  const emptyDevisOrProposition: Partial<ProDevisView> = getEmptyDevis(!!company.archiType);

  const { devisList } = useDevisList({ dealUuid, showArchived: true });
  const isFirstDevisSignedImported = getIsFirstDevisSignedImported(devisList);
  const { createDevis, isLoading: isCreatingDevis } = useCreateDevis();

  const open = async (): Promise<void> => {
    const devis = await createDevis({
      devis: {
        ...emptyDevisOrProposition,
        archived: true,
        hidden: true,
        status: DevisStatuses.draft,
        lots: [],
        projectUuid,
        isTS: isFirstDevisSignedImported,
        compensationType: undefined,
      },
      dealUuid,
    });
    openModal(AddAttachmentModal, {
      customerName: deal?.customerName!,
      title: `Envoyer ${devisOrProposition({
        isArchitecte: company.isArchitecte,
        prefix: 'un',
      })} à ${deal.customerName}`,
      dealUuid,
      isArchitecte: company.isArchitecte,
      isScrollable: true,
      devisToken: devis.token,
    });
  };

  return {
    handleOpenModal: open,
    disabled: isCreatingDevis,
  };
};
