import React from 'react';

import { useFormState } from 'react-final-form';

import ChevronLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeftFilled.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';

import { HemeaLogo } from 'components/HemeaLogo';
import { useOpenDoYouReallyWantToLeaveModal } from 'hooks/useOpenDoYouReallyWantToLeaveModal';

export const StickyNavBar: React.FC<{ calendlySource: string }> = ({ calendlySource }) => {
  const { values } = useFormState();
  const openDoYouReallyWantToLeaveModal = useOpenDoYouReallyWantToLeaveModal({
    values,
    calendlySource: calendlySource,
  });

  return (
    <div className="sm-desktop:hidden pt-md pb-md flex items-center shadow-xs sticky top-0 bg-white z-20">
      <div className="z-30 ml-xs">
        <IconButton size="md" onClick={() => openDoYouReallyWantToLeaveModal()}>
          <ChevronLeft />
        </IconButton>
      </div>
      <div className="flex grow justify-center -ml-xxl">
        <HemeaLogo width={116} />
      </div>
    </div>
  );
};
