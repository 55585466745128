import React from 'react';

import DotsMenu from '@travauxlib/shared/src/components/DesignSystem/assets/DotsMenu.svg?react';
import { ActionList } from '@travauxlib/shared/src/components/DesignSystem/components/ActionList';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';
import { DealOwnerView } from '@travauxlib/shared/src/types/api/pro/DealView';

import { useUpdateDeal } from 'features/Deals/api/useUpdateDeal';
import { useOpenArchiveDealModal } from 'features/Deals/hooks/useOpenArchiveDealModal';
import { useOpenProClientDetailsModal } from 'features/Deals/hooks/useOpenProClientDetailsModal';
import { ProClient } from 'types';

import { DealOwnerInfo } from '../../Deals/components/DealHeader/DealOwnerInfo';
import { useOpenDealClientDetailsModal } from '../../Deals/hooks/useOpenDealClientDetailsModal';

type Props = {
  proClient: ProClient;
};

const useOpenDealOwnerInfoModal = (): ((dealOwner: DealOwnerView) => void) => {
  const openModal = useOpenModal();

  return (dealOwner: DealOwnerView) =>
    openModal(DealOwnerInfo, {
      dealOwner,
    });
};

export const ProClientActions: React.FC<Props> = ({ proClient }) => {
  const { updateDeal } = useUpdateDeal();
  const openProClientDetailsModal = useOpenProClientDetailsModal();
  const openDealClientDetailsModal = useOpenDealClientDetailsModal();
  const openArchiveProjectModal = useOpenArchiveDealModal();
  const openDealOwnerInfoModal = useOpenDealOwnerInfoModal();
  const isTabletOrMobile = useIsTabletOrMobile();

  const items: Array<{
    type: 'action';
    label: string;
    onClick: () => void;
    hide?: boolean;
  }> = [
    {
      type: 'action',
      label: 'Modifier les informations du client',
      onClick: () => openProClientDetailsModal(proClient),
    },
    {
      type: 'action',
      label: 'Créer un nouveau chantier',
      onClick: () =>
        openDealClientDetailsModal({
          initialValues: {
            customerName: proClient.name,
            customerEmail: proClient.email,
            customerPhoneNumber: proClient.phoneNumber,
            proClientUuid: proClient.uuid,
          },
        }),
    },
    {
      type: 'action',
      label: `${proClient.archived ? 'Désarchiver ' : 'Archiver '} tous les chantiers du client`,
      onClick: () =>
        openArchiveProjectModal({
          label: 'tous les chantiers du client ?',
          archived: proClient.archived,
          handleAccept: async () => {
            await Promise.all(
              proClient.deals.map(async deal =>
                updateDeal({ ...deal, archived: !proClient.archived }),
              ),
            );
          },
        }),
    },
    {
      type: 'action',
      hide: !proClient.latestDealOwner || !isTabletOrMobile,
      label: 'Voir le contact hemea',
      onClick: () => openDealOwnerInfoModal(proClient.latestDealOwner!),
    },
  ];

  return (
    <>
      <ActionList
        items={items}
        trigger={(toggle, referenceProps) => (
          <IconButton
            {...referenceProps}
            className="ml-sm"
            size="md"
            onClick={toggle}
            data-testid="open-pro-client-actions"
          >
            <DotsMenu />
          </IconButton>
        )}
      />
    </>
  );
};
