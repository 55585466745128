import { useNavigate } from 'react-router';

import { stringifyParams } from '@travauxlib/shared/src/utils/urls';

import { useCreateRegistration } from 'api/useCreateRegistration';

import { RegistrationFormData } from '../types';

export const useHandleCreateRegistration = (): ((
  values: RegistrationFormData,
) => Promise<void | { email: any }>) => {
  const navigate = useNavigate();
  const createRegistration = useCreateRegistration();

  const handleCreateRegistration = async (
    values: RegistrationFormData,
  ): Promise<void | { email: any }> => {
    try {
      const { token } = await createRegistration({
        ...values,
        conversionPage: document.referrer,
      });
      navigate(`../siret?${stringifyParams({ token })}`);
    } catch (error: any) {
      if (error?.response?.status === 409) {
        return { email: error.response.data };
      }
      return undefined;
    }
  };
  return handleCreateRegistration;
};
