import { useMutation, useQueryClient } from '@tanstack/react-query';

import { DealView } from '@travauxlib/shared/src/types/api/pro/DealView';
import { request } from '@travauxlib/shared/src/utils/request';

import { DEAL_KEY } from './useDeal';

const handleUpdateFeedbackRDV = ({
  feedbackRDV,
  dealUuid,
}: {
  feedbackRDV: string;
  dealUuid: string;
}): Promise<DealView> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/update-feedback-rdv`;
  return request(requestURL, {
    method: 'PATCH',
    body: {
      feedbackRDV,
    },
  });
};

export const useUpdateFeedbackRDV = (): {
  updateFeedbackRDV: typeof handleUpdateFeedbackRDV;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateFeedbackRDV, isPending } = useMutation({
    mutationFn: handleUpdateFeedbackRDV,
    onSuccess: (deal: DealView) => {
      queryClient.setQueryData([DEAL_KEY, deal.uuid], deal);
    },
  });
  return { updateFeedbackRDV, isLoading: isPending };
};
