import { v4 as uuidV4 } from 'uuid';

import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

import { CreateProDevisView } from 'features/Devis/api/useCreateDevis';

export const getMaxedPenalties = (
  chantierMontantTotalTTC: number,
  nbDaysOfDelay: number,
  restePaiementsAAppeler: number,
): number => {
  const total = roundToTwoDecimals((chantierMontantTotalTTC / 1000) * nbDaysOfDelay);
  const fivePercent = (chantierMontantTotalTTC * 5) / 100;

  // On veut que le total des pénalités ne dépasse pas 5% du total du chantier
  // ET que ça dépasse pas le restant à payer du chantier

  return Math.min(Math.min(total, fivePercent), restePaiementsAAppeler);
};

export const penaltiesDevis = ({
  prixTotal,
  nbDaysOfDelay,
}: {
  prixTotal: number;
  nbDaysOfDelay: number;
}): Partial<CreateProDevisView> => {
  const lotUuid = uuidV4();

  return {
    title: 'Pénalités de retard',
    introductionLetter: `Des imprévus peuvent parfois survenir sur votre chantier et les délais convenus ne sont donc pas respectés.
      Afin d'assurer transparence et équité, nous appliquons les pénalités de retard conformément à la norme AFNOR.
      Ces pénalités sont calculées de manière à indemniser le client pour le désagrément causé par le retard tout en respectant un cadre prédéfini.
      Le montant des pénalités est calculé en appliquant un pourcentage du montant total des travaux HT pour
      chaque jour de retard, à partir de la date de livraison initialement prévue.
      Ce pourcentage est défini dans nos CGV conformément aux recommandations AFNOR.
      Il est important de noter que ce taux ne peux excéder les limites légales en vigueur.`,
    isTS: true,
    compensationType: 'penalties',
    lots: [
      {
        label: `Pénalité de retard x ${nbDaysOfDelay} jours`,
        prixTotalHT: prixTotal * -1,
        prixTotalTTC: prixTotal * -1,
        uuid: lotUuid,
        items: [
          {
            designation: 'Montant de la pénalité de retard',
            fournitureLignes: [],
            fournitures: [],
            locations: [],
            lotUuid: lotUuid,
            montantTVA: 0,
            prixAchat: 0,
            prixHT: prixTotal * -1,
            prixPublicFournitureHT: 0,
            prixTTC: prixTotal * -1,
            prixUnitaireHT: (prixTotal / nbDaysOfDelay) * -1,
            quantite: nbDaysOfDelay,
            tauxTVA: 0,
            type: 'ligne',
            unite: 'j',
            uniteLabel: 'j',
            uuid: uuidV4(),
            hasFournitures: false,
          },
        ],
      },
    ],
  };
};

export const compensationDevis = ({
  compensationAmount,
  compensationDescription,
}: {
  compensationAmount: number;
  compensationDescription: string;
}): Partial<CreateProDevisView> => {
  const lotUuid = uuidV4();

  return {
    title: 'Dédommagement',
    introductionLetter: `Madame, Monsieur,

      Veuillez trouver ci-joint le récapitulatif des dédommagements pour les dommages causés pendant votre chantier.

      Nous vous invitons à en prendre connaissance et à nous faire part de toute remarque ou question.

      Nous vous prions d'agréer, Madame, Monsieur, nos sincères salutations.`,
    isTS: true,
    compensationType: 'compensation',
    lots: [
      {
        label: 'Dédommagement',
        prixTotalHT: -compensationAmount,
        prixTotalTTC: -compensationAmount,
        uuid: lotUuid,
        items: [
          {
            designation: 'Dédommagement',
            fournitureLignes: [],
            fournitures: [],
            locations: [],
            lotUuid: lotUuid,
            montantTVA: 0,
            prixAchat: 0,
            prixHT: -compensationAmount,
            prixPublicFournitureHT: 0,
            prixTTC: -compensationAmount,
            prixUnitaireHT: -compensationAmount,
            quantite: 1,
            tauxTVA: 0,
            type: 'ligne',
            unite: 'unitaire',
            uuid: uuidV4(),
            description: compensationDescription,
            hasFournitures: false,
          },
        ],
      },
    ],
  };
};
