import React, { useEffect } from 'react';

import classNames from 'classnames';
import { Form } from 'react-final-form';

import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import {
  ModalContent,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';
import { required, requiredValidEmail } from '@travauxlib/shared/src/utils/form';

import { useEmailRelanceContent } from 'api/profileSelectors';
import { useDeal } from 'features/Deals/api/useDeal';
import { useSendRelanceEmail } from 'features/Devis/api/useSendRelanceEmail';
import { useSetRelanceSeen } from 'features/Devis/api/useSetRelanceSeen';
import { useProClient } from 'features/ProClients/api/useProClient';
import { HybridDevis } from 'types';
import { getLinkDevisRO } from 'utils/urls';

type Props = {
  devis: HybridDevis;
  customEmailRelanceContent?: string;
  handleClose: () => void;
};

const RelanceModal: React.FC<Props> = ({ devis, customEmailRelanceContent, handleClose }) => {
  const deal = useDeal(devis.dealUuid);
  const proClient = useProClient(deal?.proClientUuid);
  const emailRelanceContent = useEmailRelanceContent();

  const setRelanceSeen = useSetRelanceSeen();
  const sendRelanceEmail = useSendRelanceEmail();

  useEffect(() => {
    setRelanceSeen({ dealUuid: devis.dealUuid, token: devis.token });
  }, [devis.dealUuid, devis.token]);

  if (!deal) {
    return <Loader />;
  }

  const customerEmail = deal.customerEmail || proClient?.email;

  return (
    <>
      <Form
        initialValues={{
          customerEmail,
          emailRelanceContent: customEmailRelanceContent || emailRelanceContent,
        }}
        onSubmit={async values => {
          await sendRelanceEmail({ devis, emailInfo: values });
          handleClose();
        }}
      >
        {({ handleSubmit, submitting }) => (
          <ModalContent
            title={`Relance du client ${deal.customerName}`}
            handleSubmit={handleSubmit}
            validateAction={{
              label: 'Envoyer au client',
              type: 'submit',
              disabled: submitting,
              loading: submitting,
            }}
            cancelAction={{ label: 'Annuler', onClick: handleClose, disabled: submitting }}
          >
            <div className="flex-1 mb-sm">
              <InputField
                name="customerEmail"
                label="Adresse email du client"
                id="email-client-input"
                disabled={!!customerEmail}
                validate={requiredValidEmail}
              />
            </div>
            <div className="flex justify-between mb-sm">
              <div className="flex-1 mr-sm">
                <InputField
                  name="emailRelanceSubject"
                  id="emailRelanceSubject"
                  label="Objet"
                  validate={required}
                />
              </div>
              <div>
                <ButtonLink
                  variant="secondary"
                  target="_blank"
                  rel="noreferrer noopener"
                  href={getLinkDevisRO(devis)}
                >
                  Consulter le devis
                </ButtonLink>
              </div>
            </div>
            <div>
              <TextareaField
                name="emailRelanceContent"
                id="emailRelanceContent"
                label="Contenu"
                className="mb-md"
                rows={8}
                maxRows={8}
                validate={required}
              />
              <CheckboxField
                className={classNames({ hidden: customEmailRelanceContent })}
                name="saveEmailRelanceContent"
                label="Utiliser ce texte pour mes prochaines relances"
              />
            </div>
          </ModalContent>
        )}
      </Form>
    </>
  );
};

export const useOpenRelanceModal = (): ((
  devis: HybridDevis,
  customEmailRelanceContent?: string,
) => void) => {
  const openModal = useOpenModal();

  return (devis: HybridDevis, customEmailRelanceContent?: string) => {
    openModal(RelanceModal, {
      devis,
      customEmailRelanceContent,
      closable: true,
    });
  };
};
