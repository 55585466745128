import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { MoveDevisModal } from 'components/MoveDevisModal';
import { HybridDevis } from 'types';

export const useOpenMoveDevisModal = (): ((devisToMove: HybridDevis, title: string) => void) => {
  const openModal = useOpenModal();
  const isTabletOrMobile = useIsTabletOrMobile();

  return (devisToMove: HybridDevis, title: string) =>
    openModal(MoveDevisModal, {
      devisToMove,
      title,
      closable: true,
      isScrollable: isTabletOrMobile,
    });
};
