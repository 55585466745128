import React, { useMemo } from 'react';

import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';

import { Toggles } from '@travauxlib/shared/src/features/Configurateur/components/Toggles';
import { Summary } from '@travauxlib/shared/src/features/Project/components/Summary';
import { BaseDePrix, Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';

import { useGetBaseDePrix } from 'features/BaseDePrix/api/useGetBaseDePrix';
import { Documents } from 'features/ConsultationOffer/components/Documents';

import { EmptyConfigurationForm } from './components/EmptyConfigurationForm';

type Props = {
  configuration: Configuration;
  files: {
    downloadUrl: string;
    name: string;
    key: string;
  }[];
};

export const EstimationDetails: React.FC<Props> = ({ configuration, files }) => {
  const { lines } = useGetBaseDePrix();

  const baseDePrix = useMemo(
    () => _keyBy(lines, item => item.normalizedIdentifier),
    [lines],
  ) as BaseDePrix;

  return (
    <div className="flex flex-col gap-xl mt-md lg-desktop:pl-[94px] lg-desktop:pr-[124px]">
      <EmptyConfigurationForm configuration={configuration}>
        <div>
          <Summary googleApiKey={APP_CONFIG.googleApiKey} project={configuration} />
        </div>
      </EmptyConfigurationForm>
      {!_isEmpty(configuration.answers) && (
        <div>
          <Toggles readOnly baseDePrix={baseDePrix} configuration={configuration} />
        </div>
      )}
      <div>
        <Documents files={files} />
      </div>
    </div>
  );
};
