import { SuiviChantier } from '@travauxlib/shared/src/types';

import { HybridDevis } from 'types';

import { useGetSuiviChantierList } from '../api/useGetSuiviChantierList';

export const useDevisSuiviChantier = (
  devis: HybridDevis,
): { suiviChantier: SuiviChantier | undefined; isLoading: boolean } => {
  const { suiviChantierList, isLoading } = useGetSuiviChantierList(devis.dealUuid);

  return {
    suiviChantier: suiviChantierList.find(
      suiviChantier => suiviChantier.proDevisToken === devis.token,
    ),
    isLoading,
  };
};
