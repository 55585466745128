import { FC, useCallback, useRef, useState } from 'react';

import classNames from 'classnames';

import Assistance from '@travauxlib/shared/src/components/DesignSystem/assets/AssistanceFilled.svg?react';
import Attachment from '@travauxlib/shared/src/components/DesignSystem/assets/AttachmentFilled.svg?react';
import DocumentFilled from '@travauxlib/shared/src/components/DesignSystem/assets/DocumentFilled.svg?react';
import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbolFilled.svg?react';
import SortBy from '@travauxlib/shared/src/components/DesignSystem/assets/SortBy.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { useOnClickOutside } from '@travauxlib/shared/src/hooks/useOnClickOutside';
import { DealView } from '@travauxlib/shared/src/types/api/pro/DealView';

import { useProfile } from 'api/useProfile';
import { useOpenConversationInfoModal } from 'features/Deals/hooks/useOpenConversationInformationModal';

import { ConversationDropZone } from '../ConversationDropZone';

type ConversationMobileMenuProps = {
  deal: DealView;
};

export const ConversationMobileMenu: FC<ConversationMobileMenuProps> = ({ deal }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const ignoreOutsideClick = 'ignore';
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const onClick = useCallback(() => {
    setIsOpened(isOpened => !isOpened);
  }, [setIsOpened, isOpened]);

  const handleClose = useCallback(() => {
    setIsOpened(false);
  }, [setIsOpened]);

  const { profile } = useProfile();
  const openConversationModal = useOpenConversationInfoModal();

  useOnClickOutside(containerRef, handleClose, { outsideClickIgnoreClass: ignoreOutsideClick });

  return (
    <>
      <IconButton
        type="button"
        onClick={onClick}
        className={`self-center mr-xs ${ignoreOutsideClick}`}
        variant="primary"
      >
        <PlusSymbol className={classNames({ 'rotate-45': isOpened })} />
      </IconButton>
      {isOpened && (
        <div
          ref={containerRef}
          className="absolute bg-white left-0 -top-xs animate-slide-in -translate-y-full !w-full shadow-xs"
        >
          <div
            role="button"
            className="hover:bg-primarylighter p-0 bg-transparent !w-full !flex !items-center !py-md !px-sm"
            onClick={() => {
              setIsOpened(false);
              openConversationModal({
                profile,
                dealUuid: deal.uuid,
                activeTab: { category: 'participants', mode: 'consult' },
              });
            }}
          >
            <Assistance className="w-lg h-lg mr-xmd text-primary" /> Gérer les participants
          </div>
          <div
            role="button"
            className="hover:bg-primarylighter bg-transparent p-0 !w-full !flex !items-center !py-md !px-sm"
            onClick={() => {
              setIsOpened(false);
              openConversationModal({
                profile,
                dealUuid: deal.uuid,
                activeTab: { category: 'documents' },
              });
            }}
          >
            <DocumentFilled className="w-lg h-lg mr-xmd text-primary" /> Voir les documents partagés
          </div>
          <div
            role="button"
            className="hover:bg-primarylighter bg-transparent p-0 !w-full !flex !items-center !py-md !px-sm"
            onClick={() => {
              setIsOpened(false);
              openConversationModal({
                profile,
                dealUuid: deal.uuid,
                activeTab: { category: 'notes' },
              });
            }}
          >
            <SortBy className="w-lg h-lg mr-xmd text-primary" /> Créer une note
          </div>
          <ConversationDropZone
            conversationUuid={deal.conversationUuid}
            trigger={({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input data-testid="drop-file" {...getInputProps()} />
                <div
                  role="button"
                  className="hover:bg-primarylighter p-0 bg-transparent !w-full !flex !items-center !py-md !px-sm"
                  onClick={handleClose}
                >
                  <Attachment data-testid="add-pj" className="w-lg h-lg text-primary mr-xmd" />
                  Ajouter un document
                </div>
              </div>
            )}
          />
        </div>
      )}
    </>
  );
};
