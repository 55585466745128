import React from 'react';

import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { QuantityLabel } from '@travauxlib/shared/src/features/DevisDisplay/components/QuantityLabel';
import { SuiviLigneLegacy } from '@travauxlib/shared/src/types';
import { DevisLocation } from '@travauxlib/shared/src/types/api/domain/prodevisfactures/DevisLocation';

type Props = {
  ligne: SuiviLigneLegacy;
  locations: DevisLocation[];
};

export const DevisLocations: React.FC<Props> = ({ ligne, locations }) => {
  const locationsLabel: { [K: string]: string } = locations.reduce(
    (acc, { uuid, label }) => ({
      ...acc,
      [uuid]: label,
    }),
    {},
  );
  const locationsWithLabel = ligne.locations
    .filter(location => locationsLabel[location.uuid] !== undefined)
    .map(location => ({
      ...location,
      label: locationsLabel[location.uuid],
    }));
  return (
    <>
      {' '}
      {locationsWithLabel.length !== 0 && (
        <div className="flex justify-start">
          <div className="h5 pb-xxs">
            {locationsWithLabel.map(
              location =>
                location && (
                  <Tag
                    variant="primary"
                    size="md"
                    className="mr-xs"
                    key={location.uuid}
                    label={
                      <>
                        {location.label}
                        &nbsp;:&nbsp;
                        <QuantityLabel unite={ligne.unite} quantite={location.quantite} />
                      </>
                    }
                  />
                ),
            )}
          </div>
        </div>
      )}
    </>
  );
};
