import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { DevisCustomization } from '@travauxlib/shared/src/types';
import { ProUserProfileView } from '@travauxlib/shared/src/types/api/common/ProUserProfileView';
import { request, isConflict } from '@travauxlib/shared/src/utils/request';

import { AUTH_KEY } from './useProfile';

export type UpdateProfilePayload = {
  email: string;
  phoneNumber: string;
  fullName: string;
  webPushId?: string;
  isWebPushEnabled?: boolean;
  devisCustomizationConfig?: DevisCustomization;
  signature?: {
    content: string;
    enabled: boolean;
    logoEnabled: boolean;
  };
  hasSeenDevisTsAnnouncement?: boolean;
};

const handleUpdateProfile = (payload: UpdateProfilePayload): Promise<ProUserProfileView> => {
  const requestURL = `${APP_CONFIG.apiURL}/pro/users`;

  return request(requestURL, {
    method: 'PUT',
    body: JSON.stringify(payload),
  });
};

export const useUpdateProfile = (
  shouldSilenceNotification?: boolean,
): {
  updateProfile: typeof handleUpdateProfile;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateProfile, isPending } = useMutation({
    mutationFn: handleUpdateProfile,
    onSuccess: (profile: ProUserProfileView) => {
      queryClient.setQueryData([AUTH_KEY], profile);
      if (!shouldSilenceNotification) {
        toast.success('Les modifications ont été prises en compte !');
      }
    },
    onError: (error: Error) => {
      const message = isConflict(error)
        ? "L'email est déjà utilisé par un autre utilisateur"
        : "Il y a eu un problème, les modifications n'ont pas pu être sauvegardées";
      toast.error(message);
    },
  });
  return { updateProfile, isLoading: isPending };
};
