import React, { useState } from 'react';

import _groupBy from 'lodash/groupBy';
import { useFormState } from 'react-final-form';
import { useNavigate, useParams } from 'react-router';

import PaymentsDuoTone from '@travauxlib/shared/src/components/DesignSystem/assets/PaymentsDuoTone.svg?react';
import { AlertMessage } from '@travauxlib/shared/src/components/DesignSystem/components/AlertMessage';
import { LayoutGrid } from '@travauxlib/shared/src/components/DesignSystem/components/Layout';
import { Header } from '@travauxlib/shared/src/features/SuiviChantier/components/Header';
import { SuiviFooter } from '@travauxlib/shared/src/features/SuiviChantier/components/SuiviFooter';
import { ThresholdInfo } from '@travauxlib/shared/src/features/SuiviChantier/components/ThresholdInfo';
import { SummaryLotRow } from '@travauxlib/shared/src/features/SuiviChantier/features/Suivi/components/MainSuivi/SummaryLotRow';
import { useOpenThresholdModal } from '@travauxlib/shared/src/features/SuiviChantier/hooks/useOpenThresholdModal';
import { FormData, SuiviLot } from '@travauxlib/shared/src/features/SuiviChantier/types';
import {
  doesSuiviContainsAChange,
  getAvancementForLots,
  parseFormDataToGetLots,
} from '@travauxlib/shared/src/features/SuiviChantier/utils';
import { computeSuiviChantierAmounts } from '@travauxlib/shared/src/features/SuiviChantier/utils/computeSuiviChantierAmounts';
import { computeThreshold } from '@travauxlib/shared/src/features/SuiviChantier/utils/threshold';
import { AdminEventsProperties } from '@travauxlib/shared/src/utils/tracking';

import { AvancementChantierPro } from 'utils/tracking/AvancementChantierTracking';

import { ConfirmationDemandePaiementModal } from './ConfirmationDemandePaiementModal';
import { FooterAmounts } from './FooterAmounts';

import { useSuiviChantier } from '../../api/useSuiviChantier';

export const MainSuivi: React.FC = () => {
  const { uuid: chantierUuid } = useParams<{ uuid: string }>();
  const { suiviChantier, isLoading } = useSuiviChantier(chantierUuid!);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const { values } = useFormState<FormData>();
  const navigate = useNavigate();
  const openThresholdModal = useOpenThresholdModal();

  if (isLoading || !suiviChantier) {
    return null;
  }

  const {
    customerName,
    devisList,
    montantRemise,
    montantDejaPaye,
    totalMontantTVA,
    receptionChantierThreshold,
    leveeReservesThreshold,
    isReceptionChantierPvValidated,
    isLeveeReservesPvValidated,
    hasReserves,
  } = suiviChantier;

  const lots = parseFormDataToGetLots(values);
  const remiseMapByDevisToken = devisList.reduce(
    (acc, devis) => ({
      ...acc,
      [devis.token]: devis.remises,
    }),
    {},
  );

  const { currentThreshold, thresholdInfo } = computeThreshold({
    lots,
    receptionChantierThreshold,
    leveeReservesThreshold,
    isReceptionChantierPvValidated,
    isLeveeReservesPvValidated,
    hasReserves,
    isPro: true,
  });

  const computed = computeSuiviChantierAmounts({
    lots: lots,
    remisesMapByDevisToken: remiseMapByDevisToken,
    montantRemise: montantRemise,
    montantDejaPaye: montantDejaPaye,
    totalMontantTVA: totalMontantTVA,
    threshold: currentThreshold,
  });

  const groupedLots: Record<string, SuiviLot[]> = _groupBy(lots, 'label');
  const hasMontantGele = computed.montantGele > 0;
  const isMontantALibererPositive = computed.montantALibererTTC > 0;
  const canSubmitSuivi =
    isMontantALibererPositive || (hasMontantGele && computed.atLeastOneLineHasChanged);

  const handleOpenThresholdModal = (): void => {
    openThresholdModal({
      leveeReservesThreshold,
      receptionChantierThreshold,
      openCrisp: () => window.$crisp.push(['do', 'chat:open']),
      isForPro: true,
    });
    AvancementChantierPro.onPlafondEnSavoirPlus({
      [AdminEventsProperties.DealUUID]: chantierUuid!,
    });
  };

  return (
    <>
      <Header
        onClick={() => navigate(`/deals/${chantierUuid}/chantier`)}
        Icon={PaymentsDuoTone}
        title={`Chantier de ${customerName}`}
        subTitle="Demander un paiement"
        pourcentageAvancement={getAvancementForLots(lots)}
        shouldDisplayInfoColor={doesSuiviContainsAChange(lots)}
      />
      {!canSubmitSuivi && (
        <AlertMessage className="shadow-md border-x-0 border-t-0 rounded-none" level="warning">
          <div className="sm-desktop:text-b1">
            Le montant d’avancement total du chantier doit être supérieur au montant déjà payé par
            le client pour libérer des fonds supplémentaires.
          </div>
        </AlertMessage>
      )}
      <LayoutGrid className="overflow-auto h-full !mx-0 grid-rows-[max-content]">
        <div className="col-span-full sm-desktop:col-start-2 m-md sm-desktop:mx-0">
          <div className="text-h4 font-bold text-neutral-800">État d'avancement</div>
          <div className="text-h5 font-medium text-neutral-700">
            Sélectionnez un lot du chantier
          </div>
        </div>
        <div className="col-span-full sm-desktop:col-span-6 sm-desktop:col-start-2 pb-md sm-desktop:flex sm-desktop:flex-col">
          {Object.entries(groupedLots).map(([lotLabel, lots]) => (
            <SummaryLotRow
              key={lotLabel}
              label={lotLabel}
              pourcentageAvancement={getAvancementForLots(lots)}
              onClick={() => navigate(lots[0].uuid)}
              devisNumbers={lots.map(lot => lot.devisNumber)}
              containsAChange={doesSuiviContainsAChange(lots)}
            />
          ))}
        </div>
        <SuiviFooter
          lots={lots}
          computed={computed}
          action={{
            label: isMontantALibererPositive ? 'Demander le paiement' : "Envoyer l'avancement",
            disabled: !canSubmitSuivi,
            onClick: () => setShowConfirmationModal(true),
          }}
          intervenant="pro"
          thresholdInfo={
            thresholdInfo && (
              <ThresholdInfo
                title={`Paiements plafonnés à ${currentThreshold}%`}
                content={thresholdInfo.thresholdCard}
                onClick={handleOpenThresholdModal}
              />
            )
          }
        >
          {({ showDetails, setShowDetails }) => (
            <FooterAmounts
              showDetails={showDetails}
              setShowDetails={setShowDetails}
              montantALibererTTC={computed.montantALibererTTC}
              montantGele={computed.montantGele}
              openThresholdModal={handleOpenThresholdModal}
            />
          )}
        </SuiviFooter>
      </LayoutGrid>
      <ConfirmationDemandePaiementModal
        isOpen={showConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        montantALibererTTC={computed.montantALibererTTC}
        avancementGlobal={getAvancementForLots(lots)}
        customerName={customerName}
      >
        {thresholdInfo ? (
          <AlertMessage level="info" className="mb-xs">
            {thresholdInfo.confirmationModal}
          </AlertMessage>
        ) : null}
      </ConfirmationDemandePaiementModal>
    </>
  );
};
