import React from 'react';

import { ProCompanyUserView } from '@travauxlib/shared/src/types/api/pro/ProCompanyUserView';

import { HybridDeal, ProClient } from 'types';

import { DealInformationBackLink } from './DealInformationBackLink';

type Props = {
  proClient: ProClient;
  deal?: HybridDeal;
  proUser?: ProCompanyUserView;
};

export const DealInformation: React.FC<Props> = ({ proClient, deal }) => {
  const clientName = deal && 'customerName' in deal ? deal.customerName : proClient.name;
  const showBackLink = deal && proClient.deals.length > 1;

  return (
    <>
      {showBackLink && <DealInformationBackLink {...proClient} />}
      <>
        <div className="text-h4 mb-xs font-bold">{clientName}</div>
        {deal?.title && <div className="!font-bold !mb-xs text-h5">{deal.title}</div>}
      </>
    </>
  );
};
