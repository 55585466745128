import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import { request } from '@travauxlib/shared/src/utils/request';

const fetchConfiguration = async ({
  queryKey: [, dealUuid],
}: QueryFunctionContext<[string, string]>): Promise<Configuration | undefined> => {
  try {
    return await request(`${APP_CONFIG.apiURL}/pro/deals/${dealUuid}/configuration`);
  } catch {
    return undefined;
  }
};

export const useConfiguration = (
  dealUuid: string,
): { isLoading: boolean; configuration: Configuration | undefined } => {
  const { isLoading, data } = useQuery<Configuration | undefined>({
    queryKey: ['configuration', dealUuid],
    queryFn: fetchConfiguration,
    retry: false,
  });

  return { isLoading, configuration: data };
};
