import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { SuiviChantier } from '@travauxlib/shared/src/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { HybridDevis } from 'types';
import { SuiviDeChantierTracking } from 'utils/tracking/SuiviDeChantierTracking';
import { getSuiviChantierRootUrl } from 'utils/urls';

const handleCreateSuiviChantier = (devis: HybridDevis): Promise<SuiviChantier> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${devis.dealUuid}/devis/${devis.token}/suivi-chantier`;
  return request(requestURL, { method: 'POST' });
};

export const useCreateSuiviChantier = (): {
  createSuiviChantier: typeof handleCreateSuiviChantier;
  isLoading: boolean;
} => {
  const navigate = useNavigate();
  let { mutateAsync: createSuiviChantier, isPending } = useMutation({
    mutationFn: handleCreateSuiviChantier,
    onSuccess: (suivi: SuiviChantier) => {
      SuiviDeChantierTracking.onSuiviDeChantierCreated({ uuid: suivi.uuid });
      navigate(getSuiviChantierRootUrl(suivi));
    },
    onError: () => {
      toast.error("Le suivi de chantier n'a pas pu être généré");
    },
  });
  return { createSuiviChantier, isLoading: isPending };
};
