import React, { useState } from 'react';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Textarea } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea';
import { DealView } from '@travauxlib/shared/src/types/api/pro/DealView';

import { useUpdateFeedbackRDV } from 'features/Deals/api/useUpdateFeedbackRDV';

type Props = {
  deal: DealView;
};

export const FeedbackRDV: React.FC<Props> = ({ deal: { uuid } }) => {
  const [feedbackRDV, setFeedbackRDV] = useState('');
  const { updateFeedbackRDV, isLoading } = useUpdateFeedbackRDV();

  return (
    <div className="!text-center">
      <div className="!mb-xmd">
        Quel est votre ressenti sur le rendez-vous ? Dites-nous en plus !
      </div>
      <div className="!mx-xmd">
        <Textarea
          data-testid="feedbackRDV"
          id="feedbackRDV"
          rows={6}
          value={feedbackRDV}
          onChange={setFeedbackRDV}
        />
        <div className="!flex !justify-center">
          <Button
            variant="secondary"
            type="button"
            size="sm"
            disabled={isLoading}
            className="!mr-xs"
            onClick={() =>
              updateFeedbackRDV({
                feedbackRDV: "Le pro n'a pas émis de ressenti",
                dealUuid: uuid,
              })
            }
          >
            Passer
          </Button>
          <Button
            type="button"
            disabled={isLoading || !feedbackRDV}
            size="sm"
            onClick={() => updateFeedbackRDV({ feedbackRDV, dealUuid: uuid })}
          >
            Valider
          </Button>
        </div>
      </div>
    </div>
  );
};
