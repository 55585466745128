import React from 'react';

import { Form } from 'react-final-form';

import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { CompanyType } from '@travauxlib/shared/src/types/api/domain/procompany/CompanyType';
import { validateConditions } from '@travauxlib/shared/src/utils/form';

import { useProCompany } from 'api/profileSelectors';
import { useUpdateProCompany } from 'api/useUpdateProCompany';
import { CgvLabel } from 'components/CgvLabel';

export const ArchiCgvModal: React.FC = () => {
  const company = useProCompany();
  const { updateProCompany } = useUpdateProCompany();

  if (!company?.isArchitecte || company?.hasSignedArchiCgv) {
    return null;
  }

  return (
    <Modal isOpen title="Actualisation de l'activité de votre entreprise">
      <Form onSubmit={() => updateProCompany({ ...company, hasSignedArchiCgv: true })}>
        {({ handleSubmit, invalid, submitting }) => (
          <ModalContent
            handleSubmit={handleSubmit}
            validateAction={{
              type: 'submit',
              label: 'Valider',
              disabled: invalid,
              loading: submitting,
            }}
          >
            <p className="mb-md">
              Nous avons modifié votre interface afin qu'elle soit adaptée à votre activité
              d'architecte. Pour valider ce changement, merci de lire et accepter les CGU, CGS de
              hemea et CGUV Club Pro.
            </p>
            <CheckboxField
              validate={validateConditions}
              name="acceptCgv"
              label={<CgvLabel companyType={CompanyType.Archi} />}
            />
          </ModalContent>
        )}
      </Form>
    </Modal>
  );
};
