import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { ProDevisView } from '@travauxlib/shared/src/types/api/pro/ProDevisView';
import { request } from '@travauxlib/shared/src/utils/request';

import { LAST_DEVIS_KEY } from 'features/Deals/features/Devis/api/useLastDevis';
import { fadingTime } from 'features/Deals/utils/constants';

import { DEVIS_LIST_KEY } from './useDevisList';

export type CreatePayload = {
  dealUuid: string;
  devis: Partial<ProDevisView>;
};

const handleDuplicateDevis = ({ devis, dealUuid }: CreatePayload): Promise<ProDevisView> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/devis/${devis.token}/duplicate`;
  return request(requestURL, {
    method: 'POST',
    body: devis,
  });
};

export const useDuplicateDevis = (): {
  duplicateDevis: typeof handleDuplicateDevis;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: duplicateDevis, isPending } = useMutation({
    mutationFn: handleDuplicateDevis,
    onSuccess: (devis: ProDevisView) => {
      queryClient.setQueryData(
        [DEVIS_LIST_KEY, devis.dealUuid],
        (devisListCached: ProDevisView[] = []) => [
          ...devisListCached,
          { ...devis, isHighlighted: true },
        ],
      );
      queryClient.invalidateQueries({ queryKey: [LAST_DEVIS_KEY] });
      // We set a timeout to avoid the blink of animation if you switch tabs
      setTimeout(
        () => queryClient.refetchQueries({ queryKey: [DEVIS_LIST_KEY, devis.dealUuid] }),
        fadingTime,
      );
      toast.success('Le devis a bien été dupliqué');
    },
    onError: () => {
      toast.error("Le devis n'a pas pu être dupliqué");
    },
  });
  return { duplicateDevis, isLoading: isPending };
};
