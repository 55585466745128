import { useNavigate } from 'react-router';

import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { ClubProPaywallModal } from 'components/ClubProPaywallModal/ClubProPaywallModal';
import { dashboardUrl } from 'utils/urls';

export const useOpenClubProPaywall = ({
  isExistingDeal,
}: {
  isExistingDeal: boolean;
}): (() => void) => {
  const navigate = useNavigate();
  const openModal = useOpenModal();
  return () =>
    openModal(ClubProPaywallModal, {
      size: 'md',
      handleClose: () => {
        navigate(dashboardUrl);
      },
      isExistingDeal,
    });
};
