import React from 'react';

import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { ProDevisView } from '@travauxlib/shared/src/types/api/pro/ProDevisView';
import { required } from '@travauxlib/shared/src/utils/form';

import { ChoiceFactureFromDevis } from './ChoiceFactureFromDevis';

type Props = {
  devisSelected?: ProDevisView;
  devisSignes: ProDevisView[];
};

export const FacturerFromDevis: React.FC<Props> = props => {
  const { devisSignes, devisSelected } = props;
  return (
    <div>
      <div className="mt-xl mb-lg">
        <DropdownField
          id="devis"
          label="Sélectionner un devis signé"
          name="devis"
          options={devisSignes.map(devis => ({
            value: devis,
            label: devis.title,
          }))}
          disableInput
          validate={required}
        />
      </div>
      {devisSelected && <ChoiceFactureFromDevis devisSelected={devisSelected} />}
    </div>
  );
};
