import React from 'react';

import { useFormState } from 'react-final-form';

import CheckSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CheckSymbolFilled.svg?react';
import ChevronLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeftFilled.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';

import { FiveStarsExcellentTrustpilot } from 'components/FiveStarsExcellentTrustpilot';
import { HemeaLogo } from 'components/HemeaLogo';
import { useOpenDoYouReallyWantToLeaveModal } from 'hooks/useOpenDoYouReallyWantToLeaveModal';

import { Cartouche, Position, Size } from './Cartouche';

export type ReassuranceColumnConfig = {
  reassuranceSentences: string[];
  cartouchesTopData: { label: string; imgUrl: string; size: Size; position: Position }[];
  cartouchesBottomData: { label: string; imgUrl: string; size: Size; position: Position }[];
  calendlySource: string;
};

const IconChevronLeft: React.FC = () => (
  <div className="flex items-center justify-center">
    <ChevronLeft />
  </div>
);

const GoBackHomeDiv: React.FC = () => (
  <div className="text-b1 ml-sm">Retourner sur la page d'accueil</div>
);

export const ReassuranceColumn: React.FC<{ config: ReassuranceColumnConfig }> = ({
  config: { calendlySource, cartouchesTopData, cartouchesBottomData, reassuranceSentences },
}) => {
  const { values } = useFormState();
  const openDoYouReallyWantToLeaveModal = useOpenDoYouReallyWantToLeaveModal({
    values,
    calendlySource,
  });

  return (
    <div className="hidden md-desktop:block max-w-6/12 bg-neutral-100">
      <div className="pl-xl pt-xl pb-xs">
        <HemeaLogo width={116} />
      </div>
      <div className="flex mb-xmd ml-lg items-center">
        {calendlySource === 'exit-during-demande-chantier' ? (
          <ButtonLink
            variant="tertiary"
            href={`${APP_CONFIG.wwwURL}/fr/pro/tarifs`}
            leftIcon={<IconChevronLeft />}
          >
            <GoBackHomeDiv />
          </ButtonLink>
        ) : (
          <Button
            variant="tertiary"
            onClick={openDoYouReallyWantToLeaveModal}
            leftIcon={<IconChevronLeft />}
          >
            <GoBackHomeDiv />
          </Button>
        )}
      </div>
      <div className="flex gap-md pl-xxl pb-md pr-xl">
        {cartouchesTopData.map(cartoucheData => (
          <Cartouche
            position={cartoucheData.position}
            label={cartoucheData.label}
            size={cartoucheData.size}
            key={cartoucheData.label}
          >
            <ImgLazy
              sizes="99%"
              url={cartoucheData.imgUrl}
              alt={cartoucheData.label}
              className="w-full h-full"
            />
          </Cartouche>
        ))}
      </div>
      <div className="flex gap-md pl-xl pb-xl">
        {cartouchesBottomData.map(cartoucheData => (
          <Cartouche
            position={cartoucheData.position}
            label={cartoucheData.label}
            size={cartoucheData.size}
            key={cartoucheData.label}
          >
            <ImgLazy
              sizes="99%"
              url={cartoucheData.imgUrl}
              alt={cartoucheData.label}
              className="w-full h-full"
            />
          </Cartouche>
        ))}
      </div>
      <div className="pl-xl pb-lg text-neutral-800">
        {reassuranceSentences.map(sentence => (
          <div key={sentence} className="flex items-center mb-md">
            <CheckSymbol className="text-primary mr-xmd size-lg" />
            {sentence}
          </div>
        ))}
      </div>
      <FiveStarsExcellentTrustpilot />
    </div>
  );
};
