import React, { FC } from 'react';

import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { Tabs } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs';
import { useFeatureFlag } from '@travauxlib/shared/src/hooks/useFeatureFlag';
import { ProUserProfileView } from '@travauxlib/shared/src/types/api/common/ProUserProfileView';

import { useDeal } from 'features/Deals/api/useDeal';

import { ConversationModalTab } from './type';

import { MessageAttachmentList } from '../MessageAttachmentList';
import { ParticipantHandler } from '../ParticipantHandler';
import { PersonalNotes } from '../PersonalNotes';

export type ConversationInformationModalProps = {
  dealUuid: string;
  profile: ProUserProfileView;
  activeTab?: ConversationModalTab;
};

export const ConversationInformationModal: FC<ConversationInformationModalProps> = ({
  dealUuid,
  profile,
  activeTab,
}) => {
  const deal = useDeal(dealUuid);
  const showNotes = useFeatureFlag('notes');
  const showDocuments = useFeatureFlag('documents');
  const showParticipants = !showNotes && !showDocuments;

  if (!deal) {
    return null;
  }

  const { conversationUuid, notes } = deal;

  return (
    <ModalContent>
      <div className="overflow-hidden py-xmd">
        <Tabs
          fullWidth
          items={[
            {
              label: 'Participants',
              to: {
                search: 'participants',
              },
              isActive: () => showParticipants,
            },
            {
              label: 'Documents partagés',
              to: {
                search: 'documents',
              },
              isActive: () => showDocuments,
            },
            {
              label: 'Notes',
              to: {
                search: 'notes',
              },
              isActive: () => showNotes,
            },
          ]}
        />
        <div className="overflow-auto flex flex-col h-[430px] max-h-[430px]">
          {showParticipants && (
            <ParticipantHandler
              mode={activeTab?.mode || 'consult'}
              deal={deal}
              profile={profile}
              participantToEdit={activeTab?.participant}
            />
          )}
          {showNotes && <PersonalNotes deal={deal} />}
          {showDocuments && (
            <MessageAttachmentList conversationUuid={conversationUuid} notes={notes} />
          )}
        </div>
      </div>
    </ModalContent>
  );
};
