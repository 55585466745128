import React from 'react';

import { HybridDevis } from 'types';

import { Choice } from './Choice';

type Props = {
  handleClose: () => void;
  devis: HybridDevis;
};

export const FacturerDevisModal: React.FC<Props> = ({ handleClose, devis }) => (
  <Choice devis={devis} onClose={handleClose} />
);
