import { ProUserProfileView } from '@travauxlib/shared/src/types/api/common/ProUserProfileView';
import { DealView } from '@travauxlib/shared/src/types/api/pro/DealView';

import { ConversationParticipant } from 'types';

export type ParticipantStatus = 'readwrite' | 'readonly' | 'hidden';

export const getParticipantStatus = (
  participant: ConversationParticipant,
  deal: DealView,
  profile: ProUserProfileView,
): ParticipantStatus => {
  if (!participant.hidden) {
    return 'readwrite';
  }

  const isCurrentUser = participant.email === profile.email;
  const isCustomer = participant.email === deal.customerEmail;
  const isDealOwner = participant.email === deal.dealOwner?.email;

  return isCurrentUser || isCustomer || isDealOwner ? 'readonly' : 'hidden';
};
