import React from 'react';

import { Form } from 'react-final-form';
import { useParams } from 'react-router';

import { AutoSubmitForm } from '@travauxlib/shared/src/components/AutoSubmitForm';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { SuiviChantier as SuiviChantierType } from '@travauxlib/shared/src/types';
import { getCompletionFromLignes } from '@travauxlib/shared/src/utils/getCompletionFromLignes';

import { useGetSuiviChantier } from './api/useGetSuiviChantier';
import { useUpdateSuiviChantier } from './api/useUpdateSuiviChantier';
import { SuiviHeader } from './components/SuiviHeader';
import { SuiviLot } from './components/SuiviLot';

export const SuiviChantier: React.FC = () => {
  const { token, uuid: dealUuid } = useParams<{ token: string; uuid: string }>();
  const { suiviChantier, isLoading } = useGetSuiviChantier({ token: token!, dealUuid: dealUuid! });
  const { updateSuiviChantier } = useUpdateSuiviChantier();

  const handleSubmit = async (values: SuiviChantierType): Promise<void> => {
    await updateSuiviChantier(values);
  };

  if (isLoading || !suiviChantier) {
    return <Loader />;
  }

  const completion = getCompletionFromLignes(suiviChantier.lots.flatMap(lot => lot.lignes));

  return (
    <Form<SuiviChantierType>
      onSubmit={handleSubmit}
      initialValues={suiviChantier}
      initialValuesEqual={(a, b) => a?.updatedAt === b?.updatedAt}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <AutoSubmitForm onSubmit={handleSubmit} debounce={2000} />
          <SuiviHeader
            completionPercentage={completion.percentage}
            completionAmount={completion.amount}
            dealUuid={suiviChantier.dealUuid}
            lastUpdatedAt={suiviChantier.updatedAt}
            suivi={suiviChantier}
          />
          <div className="bg-lighter pt-[6rem] pb-md px-lg min-h-screen">
            <div className="rounded">
              {suiviChantier.lots.map((lot, index) => (
                <SuiviLot
                  key={lot.uuid}
                  lot={lot}
                  index={index}
                  locations={suiviChantier.locations}
                />
              ))}
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
