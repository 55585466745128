import { useEffect, useState, useRef, RefObject } from 'react';

import { useLocation } from 'react-router';

import { useOnClickOutside } from '@travauxlib/shared/src/hooks/useOnClickOutside';

export const useMobileSidebar = (): {
  containerRef: RefObject<HTMLDivElement | null>;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
} => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseMobileMenu = (event?: Event): void => {
    if (isOpen) {
      event?.preventDefault();
      setIsOpen(false);
    }
  };

  const location = useLocation();
  useEffect(() => {
    handleCloseMobileMenu();
  }, [location.pathname]);

  useOnClickOutside(containerRef, handleCloseMobileMenu, { event: 'mouseup' });
  return { containerRef, isOpen, setIsOpen };
};
