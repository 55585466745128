import React from 'react';

import classNames from 'classnames';

import Person from '@travauxlib/shared/src/components/DesignSystem/assets/Person.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { ImgLazy } from '@travauxlib/shared/src/components/Images/ImgLazy';
import { baseStaticS3Url } from '@travauxlib/shared/src/utils/s3StaticFiles';

import { useMultiUser } from 'features/App/hooks/useMultiUser';
import { HybridDeal, ProClient } from 'types';
import { baseImgixOnboardingUrl } from 'utils/constants';
import { getDealProUser } from 'utils/getDealProUser';

import { DealInformation } from './DealInformation';
import { DealOwnerInfo } from './DealOwnerInfo';
import { ProClientInfo } from './ProClientInfo';

type Props = {
  proClient: ProClient;
  deal?: HybridDeal;
  actions: React.ReactElement;
  isTabletOrMobile?: boolean;
};

const attestationUrl = `${baseStaticS3Url}/attestation_tva.pdf`;

export const DealHeader: React.FC<Props> = ({ proClient, deal, isTabletOrMobile, actions }) => {
  const clientEmail = deal?.customerEmail || proClient.email;
  const clientPhoneNumber = deal?.customerPhoneNumber || proClient.phoneNumber;
  const clientFormattedAddress =
    deal?.chantierFormattedAddress || proClient.billingFormattedAddress;
  const dealOwner =
    deal && 'dealOwner' in deal && deal.dealOwner ? deal?.dealOwner : proClient.latestDealOwner;
  const { proUsers, hasMultiUsersAccess } = useMultiUser();
  const dealProUser = getDealProUser(proUsers, deal);

  return (
    <div className="mb-lg md-desktop:mb-0">
      <div className="!flex !items-start !mb-xmd sm-desktop:mb-xl">
        <div
          className={classNames(
            'flex sm-desktop:inline-block px-xmd py-md rounded-md flex-grow sm-desktop:flex-grow-0 bg-primarylighter',
          )}
        >
          <div className="!flex flex-grow !items-start !justify-between">
            <div className="sm-desktop:mr-xl">
              <DealInformation proClient={proClient} deal={deal} />
              <ProClientInfo
                clientEmail={clientEmail}
                clientFormattedAddress={clientFormattedAddress}
                clientPhoneNumber={clientPhoneNumber}
                deal={deal}
              />
              {hasMultiUsersAccess && deal && dealProUser && (
                <div className="!flex !items-center !mb-xs text-sm">
                  <Person className="w-sm h-sm mr-sm" /> {dealProUser?.fullName}
                </div>
              )}
            </div>
            {actions}
          </div>
        </div>
        <Card className="ml-xmd hidden md-desktop:block self-center" state="elevated">
          <Link
            href={attestationUrl}
            rel="noopener noreferrer"
            target="_blank"
            className="!hidden md-desktop:!block self-center !text-center"
          >
            <div>
              <ImgLazy
                className="inline-block"
                url={`${baseImgixOnboardingUrl}/logiciel-de-devis-v2.png`}
                width={80}
                height={80}
                eagerLoading
              />
            </div>
            Attestation de TVA
          </Link>
        </Card>
        {dealOwner && !isTabletOrMobile && (
          <div className="ml-auto">
            <DealOwnerInfo dealOwner={dealOwner} />
          </div>
        )}
      </div>
      <ButtonLink
        className="md-desktop:hidden"
        variant="secondary"
        size="sm"
        href={attestationUrl}
        target="_blank"
        download="attestation_tva.pdf"
      >
        Télécharger l'attestation de TVA
      </ButtonLink>
    </div>
  );
};
