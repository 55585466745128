import { useQuery } from '@tanstack/react-query';

import { ProCompanyUserView } from '@travauxlib/shared/src/types/api/pro/ProCompanyUserView';
import { request } from '@travauxlib/shared/src/utils/request';

import { PRO_COMPANY_USERS_KEY } from 'utils/constants';

export const useGetProCompanyUsers = (
  archived: boolean = false,
): {
  proUsers: ProCompanyUserView[];
  isLoading: boolean;
} => {
  const { data: proUsers = [], isLoading } = useQuery<ProCompanyUserView[]>({
    queryKey: [PRO_COMPANY_USERS_KEY, archived],
    queryFn: () =>
      request(`${APP_CONFIG.apiURL}/pro/company-profile/users`, { params: { archived } }),
    staleTime: 60000,
  });

  return {
    proUsers,
    isLoading,
  };
};
