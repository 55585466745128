import React from 'react';

import classNames from 'classnames';
import { useParams } from 'react-router';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { useDeal } from 'features/Deals/api/useDeal';

import { DealLayout } from './components/DealLayout';

export const Deals: React.FC = () => {
  const { uuid } = useParams();
  const deal = useDeal(uuid);
  const isTabletOrMobile = useIsTabletOrMobile();

  if (!deal) {
    return <Loader isFullHeightScreen />;
  }

  return (
    <div
      className={classNames(
        '!absolute !overflow-auto bg-white !flex !flex-col sm-desktop:!pt-xmd !px-md md-desktop:!px-xmd top-0 left-0 right-0 bottom-0',
        {
          'top-[calc(3.75rem+1.5rem)]': isTabletOrMobile,
        },
      )}
    >
      <DealLayout deal={deal} isTabletOrMobile={isTabletOrMobile} />
    </div>
  );
};
