import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { useFuseWorker } from '@travauxlib/shared/src/hooks/useFuseWorker';
import { PartialProClientView } from '@travauxlib/shared/src/types/api/pro/PartialProClientView';
import { request } from '@travauxlib/shared/src/utils/request';

const searchOptions = {
  shouldSort: true,
  threshold: 0.3,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    {
      name: 'name',
      weight: 0.7,
    },
    {
      name: 'deals.title',
      weight: 0.3,
    },
    {
      name: 'billingAddress.address',
      weight: 0.1,
    },
    {
      name: 'billingAddress.postalCode',
      weight: 0.1,
    },
    {
      name: 'billingAddress.city',
      weight: 0.1,
    },
    {
      name: 'deals.chantierAddress.address',
      weight: 0.1,
    },
    {
      name: 'deals.chantierAddress.postalCode',
      weight: 0.1,
    },
    {
      name: 'deals.chantierAddress.city',
      weight: 0.1,
    },
  ],
};

export const PRO_CLIENTS_KEY = 'pro-clients';

export const MAX_CLIENT_DISPLAYED = 100;
export const useProClients = (
  {
    search,
    showArchived,
    skipLimit,
  }: {
    search: string;
    showArchived: boolean;
    skipLimit?: boolean;
  } = {
    search: '',
    showArchived: false,
    skipLimit: false,
  },
): {
  isLoading: boolean;
  proClients: PartialProClientView[];
} => {
  const { data: proClients = [], isLoading } = useQuery<PartialProClientView[]>({
    queryKey: [PRO_CLIENTS_KEY],
    queryFn: async () => {
      const requestURL = `${APP_CONFIG.proApiURL}/clients`;
      const { data } = await request(requestURL);

      return data;
    },
  });

  const filteredProClients = useMemo(
    () => proClients.filter(({ archived }) => archived === showArchived),
    [proClients, showArchived],
  );
  const searchResults = useFuseWorker<PartialProClientView>(
    filteredProClients,
    searchOptions,
    search,
  );
  const results = search ? searchResults : filteredProClients;

  const slicedClients = useMemo(
    () => (skipLimit ? results : results.slice(0, MAX_CLIENT_DISPLAYED)),
    [results, skipLimit],
  );

  return {
    proClients: slicedClients,
    isLoading,
  };
};
