import React from 'react';

import classNames from 'classnames';
import { FORM_ERROR } from 'final-form';
import { Form } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { required } from '@travauxlib/shared/src/utils/form';

import { useLogin } from 'api/useLogin';
import { Title } from 'components/Title';
import { forgotPasswordUrl, registrationUrl } from 'utils/urls';

type FormData = {
  email: string;
  password: string;
};

export const LoginForm: React.FC = () => {
  const login = useLogin();

  return (
    <Form<FormData>
      onSubmit={async ({ email, password }) => {
        if (!email || !password) {
          return {
            [FORM_ERROR]: true,
          };
        }
        try {
          await login({ email, password });
          return undefined;
        } catch {
          return {
            [FORM_ERROR]: true,
          };
        }
      }}
    >
      {({ submitError, handleSubmit, submitting }) => (
        <>
          <form onSubmit={handleSubmit}>
            <Title className="mb-0" title="Connectez-vous" />
            <div
              className={classNames(
                'rounded py-md px-sm text-danger mb-xs',
                submitError ? 'bg-error-50' : 'bg-transparent',
              )}
            >
              {submitError ? <>Email ou mot de passe incorrect.</> : <>&nbsp;</>}
            </div>
            <div className="mb-md">
              <InputField name="email" validate={required} label="Email" id="email-connexion" />
            </div>
            <div className="mb-md">
              <InputField
                name="password"
                validate={required}
                type="password"
                label="Mot de passe"
                id="password-connexion"
              />
              <div className="mt-xs flex justify-end">
                <Link to={forgotPasswordUrl} inline>
                  Mot de passe oublié ?
                </Link>
              </div>
            </div>
            <Button fullwidth type="submit" className="!mb-md mt-xl" disabled={submitting}>
              Se connecter
            </Button>
          </form>

          <div className="pb-xxl text-b2 flex justify-end">
            Pas encore de compte ?&nbsp;
            <Link to={registrationUrl} inline>
              Créer mon compte
            </Link>
          </div>
        </>
      )}
    </Form>
  );
};
