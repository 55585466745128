import { useQuery } from '@tanstack/react-query';

import { PartialProClientView } from '@travauxlib/shared/src/types/api/pro/PartialProClientView';
import { request } from '@travauxlib/shared/src/utils/request';

export const RELANCES_KEY = 'relances';

export const useRelances = (): PartialProClientView[] => {
  const { data: relances = [] } = useQuery<PartialProClientView[]>({
    queryKey: [RELANCES_KEY],
    queryFn: async () => {
      const requestURL = `${APP_CONFIG.proApiURL}/clients/relances`;
      const { data } = await request(requestURL);
      return data;
    },
  });
  return relances;
};
