import React from 'react';

import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbolFilled.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

import { ProgressBarSection } from './ProgressBarSection';

type Props = {
  title: string;
  addBtnOnClick?: () => void;
  progress: number;
  total: number;
  tooltips: [string, string];
  bg: '!bg-success' | '!bg-info';
};

export const ProgressBar: React.FC<Props> = ({
  title,
  addBtnOnClick,
  progress,
  total,
  tooltips,
  bg,
}) => (
  <>
    <div className="flex flex-wrap -mx-md">
      <div className="basis-0 flex-grow w-full">
        <h4 className="!mb-0 !inline">
          <strong>{title}</strong>
        </h4>
        {addBtnOnClick && (
          <Button
            type="button"
            size="sm"
            className="!mb-xs !ml-sm"
            onClick={addBtnOnClick}
            aria-label={`btnAdd${title}`}
            leftIcon={<PlusSymbol />}
          />
        )}
      </div>
      <div className="basis-0 flex-grow w-full !m-0 !text-right">
        <strong>
          <EURCurrency amount={total} forceFullPattern />
        </strong>
      </div>
    </div>
    <div
      className="flex h-md overflow-hidden bg-gray-300 text-sm !border !rounded !shadow-xs"
      //eslint-disable-next-line
      style={{ height: '3rem' }}
    >
      <ProgressBarSection
        id={`${title}_0`}
        tooltipPrefix={tooltips[0]}
        amount={progress}
        total={total}
        bg={bg}
      />
      <ProgressBarSection
        id={`${title}_2`}
        tooltipPrefix={tooltips[1]}
        amount={roundToTwoDecimals(total - progress)}
        showPercent={progress !== 0}
        total={total}
        bg="!bg-white"
      />
    </div>
  </>
);
