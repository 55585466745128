import dayjs from 'dayjs';

import { ProDevisView } from '@travauxlib/shared/src/types/api/pro/ProDevisView';

export const getInitialDevis = (devisList: ProDevisView[]): ProDevisView => {
  let [initialDevis, ...otherDevis] = devisList;

  otherDevis.forEach(devis => {
    if (dayjs(devis.dateSignature).diff(dayjs(initialDevis.dateSignature)) < 0) {
      initialDevis = devis;
    }
  });

  return initialDevis;
};
