import { useQuery } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { ProDevisByPeriodView } from '@travauxlib/shared/src/types/api/pro/ProDevisByPeriodView';
import { request } from '@travauxlib/shared/src/utils/request';

export const LAST_DEVIS_KEY = 'last-devis';
const PARTIAL_LAST_DEVIS_KEY = 'partial-last-devis';

export const useLastDevis = ({
  limit,
  isPartialView,
}: {
  limit?: number;
  isPartialView?: boolean;
}): { devisList: ProDevisByPeriodView[]; isLoading: boolean } => {
  const { data, isLoading } = useQuery<{ data: ProDevisByPeriodView[] }>({
    queryKey: [isPartialView ? PARTIAL_LAST_DEVIS_KEY : LAST_DEVIS_KEY],
    queryFn: async () => {
      try {
        const requestURL = `${APP_CONFIG.proApiURL}/devis`;

        return await request(requestURL, {
          params: {
            limit,
            // require a light version of the resource
            isPartialView,
          },
        });
      } catch (err) {
        toast.error("Les devis n'ont pas pu être récupérés");
        throw err;
      }
    },
  });

  return {
    devisList: data?.data || [],
    isLoading: isLoading,
  };
};
