import React from 'react';

import { Form } from 'react-final-form';

import { AutoSubmitForm } from '@travauxlib/shared/src/components/AutoSubmitForm';
import { EditableTextField } from '@travauxlib/shared/src/components/DesignSystem/components/EditableText/Field';
import { ProClientDealView } from '@travauxlib/shared/src/types/api/pro/ProClientDealView';

import { useUpdateDeal } from 'features/Deals/api/useUpdateDeal';

type Props = {
  deal: ProClientDealView;
};

type FormData = {
  title: string;
};

export const EditableDealTitle: React.FC<Props> = ({ deal }) => {
  const { updateDeal } = useUpdateDeal();

  return (
    <Form<FormData>
      initialValues={{ title: deal.title }}
      onSubmit={async values => {
        await updateDeal({ ...deal, ...values });
        return undefined;
      }}
      initialValuesEqual={() => true}
    >
      {({ handleSubmit }) => (
        <form onClick={e => e.stopPropagation()} onSubmit={handleSubmit}>
          <AutoSubmitForm />
          <EditableTextField
            className="font-bold mr-md"
            autoFocus
            name="title"
            dense="sm"
            id="title"
          />
        </form>
      )}
    </Form>
  );
};
