import React from 'react';

import dayjs from 'dayjs';

import Eye from '@travauxlib/shared/src/components/DesignSystem/assets/EyeFilled.svg?react';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { toCalendarFormatForDateTime } from '@travauxlib/shared/src/utils/time';

type Props = {
  viewCount?: number;
  lastSeenAt?: string;
};

export const DevisViewCount: React.FC<Props> = ({ viewCount = 0, lastSeenAt }) => {
  if (viewCount === 0) {
    return null;
  }

  return (
    <WithTooltip
      trigger={
        <div className="flex items-center">
          <Eye className="w-md h-md mr-xs" /> {viewCount}
        </div>
      }
    >
      {lastSeenAt ? (
        <div>
          Votre devis a été ouvert pour la dernière fois{' '}
          {toCalendarFormatForDateTime(dayjs(lastSeenAt))}
        </div>
      ) : (
        <div>Cet oeil indique que le client a consulté votre devis</div>
      )}
    </WithTooltip>
  );
};
