import React from 'react';

import { Navigate } from 'react-router';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { FournituresWithTracking } from '@travauxlib/shared/src/features/Fournitures/components/FournituresWithTracking';
import { ProEventsProperties } from '@travauxlib/shared/src/utils/tracking';

import { useFournitures } from 'features/Fournitures/api/useFournitures';
import { ListeFournituresTracking } from 'utils/tracking/ListeFournituresTracking';

type Props = { dealUuid: string };

export const Fournitures: React.FC<Props> = ({ dealUuid }) => {
  const { fournitures, isLoading } = useFournitures(dealUuid);

  if (isLoading) {
    return <Loader />;
  }

  if (fournitures.length === 0) {
    return <Navigate to="../devis" />;
  }

  return (
    <FournituresWithTracking
      containerClassName="-mx-xmd sm-desktop:px-xmd pb-xmd py-md"
      fournitureList={fournitures}
      userType="pro"
      doTracking={() =>
        ListeFournituresTracking.onFournituresListOpened({
          [ProEventsProperties.DealUuid]: dealUuid,
        })
      }
    />
  );
};
