import React, { useEffect } from 'react';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { DealView } from '@travauxlib/shared/src/types/api/pro/DealView';

import { useUpdateChaleurDeal } from 'features/Deals/api/useUpdateChaleurDeal';

export const chaleursDealTypes = {
  good: 'Client intéressant',
  medium: 'Client moyen',
  low: 'Client peu intéressant',
  noRDV: "Le rendez-vous n'a pas eu lieu",
};

const chaleursDeal = [
  chaleursDealTypes.good,
  chaleursDealTypes.medium,
  chaleursDealTypes.low,
  chaleursDealTypes.noRDV,
];

type Props = {
  deal: DealView;
  chaleurDeal?: string;
};

export const ChaleurDeal: React.FC<Props> = ({ chaleurDeal, deal }) => {
  const { updateChaleurDeal, isLoading } = useUpdateChaleurDeal();

  useEffect(() => {
    if (chaleurDeal) {
      updateChaleurDeal({ chaleurDeal, dealUuid: deal.uuid });
    }
  }, []);
  return (
    <div className="!text-center">
      <div className="!mb-xmd">Comment s&apos;est passé le rendez-vous avec le client ?</div>
      <div className="inline-flex flex-col items-start !text-center">
        {chaleursDeal.map(chaleurDeal => (
          <Button
            size="sm"
            key={chaleurDeal}
            className="!mb-xs"
            disabled={isLoading}
            onClick={() => updateChaleurDeal({ chaleurDeal, dealUuid: deal.uuid })}
          >
            {chaleurDeal}
          </Button>
        ))}
      </div>
    </div>
  );
};
