import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { HybridDevis } from 'types';

import { DEVIS_LIST_KEY } from './useDevisList';

const handleUnarchive = (devis: HybridDevis): Promise<HybridDevis> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${devis.dealUuid}/devis/${devis.token}/unarchive`;
  return request(requestURL, { method: 'PUT' });
};

export const useUnarchiveDevis = (): typeof handleUnarchive => {
  const queryClient = useQueryClient();

  const { mutateAsync: unarchive } = useMutation({
    mutationFn: handleUnarchive,
    onSuccess: (devis: HybridDevis) => {
      queryClient.invalidateQueries({ queryKey: [DEVIS_LIST_KEY, devis.dealUuid] });
    },
    onError: () => {
      toast.error("Le devis n'a pas pu être désarchivé");
    },
  });
  return unarchive;
};
