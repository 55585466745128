import React from 'react';

import { Navigate, Outlet, Route, Routes } from 'react-router';

import { useRedirectQuery } from '@travauxlib/shared/src/hooks/useRedirectQuery';

import { HemeaLogo } from 'components/HemeaLogo';
import { TrustpilotCol } from 'components/TrustpilotCol';
import { DemandeChantier } from 'features/DemandeChantier';
import { ChangePassword } from 'features/Login/components/ChangePassword/Loadable';
import { CreatePasswordAfterRegistration } from 'features/Login/components/CreatePasswordAfterRegistration/Loadable';
import { ForgotPassword } from 'features/Login/components/ForgotPassword/Loadable';
import { LoginForm } from 'features/Login/components/LoginForm';
import { Registration } from 'features/Registration';
import { trustPilotInfos } from 'utils/constants';
import { loginUrl } from 'utils/urls';

export const UnauthenticatedRoutes: React.FC = () => {
  const query = useRedirectQuery();
  const redirectUrl = `${loginUrl}?${query}`;

  return (
    <Routes>
      <Route
        path="demande-chantier"
        element={
          <div className="w-full px-md mx-auto !bg-primarylighter !h-full !min-h-screen">
            <DemandeChantier.FormWrapper />
          </div>
        }
      />
      <Route
        path="registration/*"
        element={
          <div className="w-full px-md mx-auto !bg-primarylighter !h-full !min-h-screen">
            <Outlet />
          </div>
        }
      >
        <Route path="account" element={<Registration.AccountStep />} />
        <Route path="siret" element={<Registration.SiretStep />} />
        <Route path="*" element={<Navigate replace to="account" />} />
      </Route>
      <Route
        path="*"
        element={
          <div className="flex h-full min-h-screen">
            <div className="p-md bg-white flex flex-col w-full sm-desktop:w-[600px] sm-desktop:grow-0 shrink-0">
              <div className="mb-xl">
                <HemeaLogo />
              </div>
              <Outlet />
            </div>
            <TrustpilotCol {...trustPilotInfos} />
          </div>
        }
      >
        <Route path="login" element={<LoginForm />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ChangePassword />} />
        <Route path="create-password" element={<CreatePasswordAfterRegistration />} />
        <Route path="*" element={<Navigate replace to={redirectUrl} />} />
      </Route>
    </Routes>
  );
};
