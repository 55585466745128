import { ProDevisView } from '@travauxlib/shared/src/types/api/pro/ProDevisView';

import { HybridDevis, DevisTemplate } from 'types';

export const getEmptyDevis = (isArchitecte?: boolean): Partial<HybridDevis> => ({
  title: isArchitecte ? "Proposition d'honoraires" : 'HybridDevis',
  lots: [],
});

export const duplicateDevis = ({ token, ...devis }: ProDevisView): ProDevisView =>
  ({
    ...devis,
    numero: undefined,
    archived: false,
  }) as any as ProDevisView;

export const isDevisTemplate = (
  devisOrDevisTemplate: HybridDevis | DevisTemplate,
): devisOrDevisTemplate is DevisTemplate =>
  (devisOrDevisTemplate as DevisTemplate).uuid !== undefined;
