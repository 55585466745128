import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { dashboardUrl } from 'utils/urls';

const handleCheckAlmaPayment = async (pid: string): Promise<void> =>
  request(`${APP_CONFIG.proApiURL}/club-pro/subscribe/alma/validate-payment`, {
    method: 'GET',
    params: {
      pid,
    },
  });

export const useCheckAlmaPayment = (): {
  checkAlmaPayment: typeof handleCheckAlmaPayment;
  isLoading: boolean;
} => {
  const navigate = useNavigate();
  const { mutateAsync: checkAlmaPayment, isPending } = useMutation({
    mutationFn: handleCheckAlmaPayment,
    onSuccess: () => {
      toast.success('Paiement validé avec succès');
      navigate(dashboardUrl);
    },
    onError: () => {
      toast.error('Impossible de valider le paiement Alma');
      navigate(dashboardUrl);
    },
  });
  return { checkAlmaPayment, isLoading: isPending };
};
