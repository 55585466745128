import React, { useState } from 'react';

import classNames from 'classnames';
import { Form } from 'react-final-form';

import Cross from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbolFilled.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { RejectionPayload } from 'features/ConsultationOffer/api/useConsultationOffer';

import { RejectReasons } from './RejectReasons';

type Props = {
  handleReject: (payload: RejectionPayload) => Promise<void>;
  noInfosProvided?: boolean;
};

export const RejectComponent: React.FC<Props> = ({ handleReject, noInfosProvided }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isTabletOrMobile = useIsTabletOrMobile();

  const toggleModal = (): void => setIsOpen(isOpen => !isOpen);

  return (
    <>
      <Button
        className={classNames('md-desktop:mb-0', { 'mr-md': noInfosProvided })}
        leftIcon={<Cross />}
        type="button"
        variant="secondary"
        size="sm"
        onClick={toggleModal}
      >
        Refuser le chantier
      </Button>
      <Modal
        isOpen={isOpen}
        isScrollable={isTabletOrMobile}
        title="Refus de la proposition"
        size="md"
        handleClose={toggleModal}
      >
        <Form<RejectionPayload>
          initialValues={{ rejectionReason: '' }}
          onSubmit={async values => {
            await handleReject(values);
            return;
          }}
        >
          {({ submitting, handleSubmit, values }) => (
            <ModalContent
              handleSubmit={handleSubmit}
              validateAction={
                values.rejectionReason
                  ? {
                      label: 'Valider',
                      type: 'submit',
                      disabled: submitting,
                    }
                  : undefined
              }
            >
              <RejectReasons handleReject={handleReject} />
            </ModalContent>
          )}
        </Form>
      </Modal>
    </>
  );
};
