import _groupBy from 'lodash/groupBy';

import { ProDevisFactureLotView } from '@travauxlib/shared/src/types/api/common/ProDevisFactureLotView';

export const mergeLots = (lots: ProDevisFactureLotView[]): ProDevisFactureLotView[] => {
  const groupedLots = _groupBy(lots, lot => lot.label);

  return Object.entries(groupedLots).map(
    ([label, lots]) =>
      ({
        label,
        uuid: lots[0].uuid,
        items: lots
          .flatMap(lot => lot.items)
          .map(item => ({
            ...item,
            lotUuid: lots[0].uuid,
          })),
      }) as ProDevisFactureLotView,
  );
};
