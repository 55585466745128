import { DealView } from '@travauxlib/shared/src/types/api/pro/DealView';

import { useCreateDeal } from 'features/Deals/api/useCreateDeal';
import { useUpdateDeal } from 'features/Deals/api/useUpdateDeal';
import {
  useCreateProClient,
  CreateProClientView,
} from 'features/ProClients/api/useCreateProClient';
import {
  useUpdateProClient,
  UpdateProClientView,
} from 'features/ProClients/api/useUpdateProClient';

export const useHandleSubmitDeal = (): ((deal: DealView) => void) => {
  const createDeal = useCreateDeal();
  const { updateDeal } = useUpdateDeal();

  return async (deal: DealView) => {
    if (deal.uuid) {
      await updateDeal(deal);
    } else {
      createDeal(deal);
    }
  };
};

const isUpdatePayload = (
  proClient: CreateProClientView | UpdateProClientView,
): proClient is UpdateProClientView => !!proClient.uuid;

export const useHandleSubmitProClient = (): ((
  payload: CreateProClientView | UpdateProClientView,
) => Promise<void>) => {
  const createProClient = useCreateProClient();
  const updateProClient = useUpdateProClient();

  return async (proClient: CreateProClientView) => {
    if (isUpdatePayload(proClient)) {
      await updateProClient(proClient);
    } else {
      createProClient(proClient);
    }
  };
};
