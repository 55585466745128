import React from 'react';

import classNames from 'classnames';
import { useMatch, useNavigate } from 'react-router';

import { OrExclusive } from '@travauxlib/shared/src/types/utils';

export type ParametersSideBarLinkProps = {
  label: string;
  icon?: React.ReactElement;
  bottomLink?: boolean;
} & OrExclusive<{ path: string }, { onClick: () => void }>;

export const SideBarLink: React.FC<ParametersSideBarLinkProps> = ({
  path,
  icon,
  label,
  onClick,
  bottomLink = false,
}) => {
  const isActive = useMatch(path!) !== null;
  const navigate = useNavigate();

  return (
    <button
      type="button"
      className={classNames(
        'text-gray-600 hover:text-dark hover:bg-primarylight active:text-dark active:bg-primarylight bg-transparent !text-left !font-bold !whitespace-nowrap !rounded !mx-xs !my-xxs',
        bottomLink ? '!p-sm flex-grow flex-row' : '!px-sm !py-xmd',
        {
          active: isActive,
        },
      )}
      onClick={() => onClick?.() || navigate(path!)}
    >
      {icon && <span className="!inline-block !mb-xxs !ml-xxs !mr-sm">{icon}</span>}
      <span className={classNames({ 'text-sm !font-bold !text-dark': bottomLink })}>{label}</span>
    </button>
  );
};
