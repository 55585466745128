import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { HybridDevis } from 'types';

import { FacturerDevisModal } from '../components/FacturerDevisModal';

export const useOpenFacturerDevisModal = (): ((devis: HybridDevis) => void) => {
  const openModal = useOpenModal();
  return (devis: HybridDevis) =>
    openModal(FacturerDevisModal, {
      title: "Création d'une facture",
      closable: true,
      devis,
    });
};
