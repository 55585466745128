import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

import { ChantierProView } from '@travauxlib/shared/src/types/api/pro/ChantierProView';
import { request } from '@travauxlib/shared/src/utils/request';

export const DEAL_CHANTIER_KEY = 'dealChantier';

const fetchChantier = async ({
  queryKey: [, dealUuid],
}: QueryFunctionContext<[string, string]>): Promise<ChantierProView | null> => {
  try {
    const result = await request(`${APP_CONFIG.apiURL}/pro/deals/${dealUuid}/chantier`);
    return result;
  } catch {
    return null;
  }
};

export const useDealChantier = (
  dealUuid: string,
): { isLoading: boolean; chantier: ChantierProView | undefined } => {
  const { isLoading, data } = useQuery<ChantierProView | null>({
    queryKey: [DEAL_CHANTIER_KEY, dealUuid],
    queryFn: fetchChantier,
    retry: false,
  });

  return { isLoading, chantier: data || undefined };
};
