import dayjs from 'dayjs';

import { ProDevisView } from '@travauxlib/shared/src/types/api/pro/ProDevisView';

export const getIsFirstDevisSignedImported = (devisList: ProDevisView[]): boolean => {
  const sortedSignedDevis = devisList
    .filter(devis => devis.status === 'signed')
    .sort((devisA, devisB) => dayjs(devisA.dateSignature).diff(dayjs(devisB.dateSignature)));

  const isFirstDevisSignedImported =
    sortedSignedDevis.length > 0 &&
    sortedSignedDevis[0].lots.some(lot => lot.label === 'Devis en pièce jointe');

  return isFirstDevisSignedImported;
};
