import React, { useState } from 'react';

import { useNavigate } from 'react-router';

import Lock from '@travauxlib/shared/src/components/DesignSystem/assets/Lock.svg?react';
import StarFull from '@travauxlib/shared/src/components/DesignSystem/assets/StarFilled.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { useCanActivateTrialPeriod, useStripePaymentFailed } from 'api/profileSelectors';
import { useActivateTrialPeriod } from 'api/useActivateTrialPeriod';
import { useOpenUnprotectedProClientDetailsModal } from 'features/Deals/hooks/useOpenUnprotectedProClientDetailsModal';
import { parametresAbonnementPaymentUrl, parametresAbonnementPlanUrl } from 'utils/urls';

type Props = {
  isExistingDeal: boolean;
};

export const ClubProPaywallModal: React.FC<Props> = ({ isExistingDeal }) => {
  const [isOpen, setIsOpen] = useState(true);
  const canActivateTrialPeriod = useCanActivateTrialPeriod();
  const stripePaymentFailed = useStripePaymentFailed();
  const navigate = useNavigate();
  const openUnprotectedProClientDetailsModal = useOpenUnprotectedProClientDetailsModal();
  const { activateTrialPeriod } = useActivateTrialPeriod();

  if (!isOpen) {
    return null;
  }

  return (
    <ModalContent>
      <div className="mb-xl text-center">
        <div className="bg-lighter inline-flex items-center rounded mb-xl py-md px-xl">
          <Lock className="w-lg h-lg mr-md" />
          <StarFull className="w-lg h-lg text-primary" />
        </div>
        <h4 className="mb-xmd font-bold">Réservé aux membres du Club Pro</h4>
        <div>
          {stripePaymentFailed ? (
            <p>Vous ne disposez pas des accès nécessaires car votre paiement a été décliné.</p>
          ) : (
            <p>
              Vous ne disposez pas des accès nécessaires car aucun abonnement n&apos;est activé sur
              votre compte.
            </p>
          )}
        </div>
      </div>
      <div className="text-center mb-md">
        <Button
          variant="primary"
          onClick={async () => {
            setIsOpen(false);
            if (canActivateTrialPeriod) {
              await activateTrialPeriod();
              if (!isExistingDeal) {
                openUnprotectedProClientDetailsModal();
              }
            } else if (stripePaymentFailed) {
              navigate(parametresAbonnementPaymentUrl);
            } else {
              navigate(parametresAbonnementPlanUrl);
            }
          }}
        >
          {canActivateTrialPeriod
            ? 'Essayer gratuitement pendant 14 jours'
            : stripePaymentFailed
              ? 'Modifier mes informations de paiement'
              : 'Voir nos offres'}
        </Button>
      </div>
    </ModalContent>
  );
};
