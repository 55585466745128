import React from 'react';

import classNames from 'classnames';

import ChevronDown from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronDownFilled.svg?react';
import ChevronUp from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronUpFilled.svg?react';
import HelpCircle from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircle.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { SimpleTotal } from '@travauxlib/shared/src/features/LDDToolbar/components/SimpleTotal';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

type Props = {
  showDetails: boolean;
  setShowDetails: (showDetails: boolean) => void;
  montantALibererTTC: number;
  montantGele: number;
  openThresholdModal: () => void;
};

const DetailsButton: React.FC<Pick<Props, 'showDetails' | 'setShowDetails'>> = ({
  showDetails,
  setShowDetails,
}) => {
  const Icon = showDetails ? ChevronUp : ChevronDown;

  return (
    <Icon
      className="inline-block sm-desktop:hidden size-lg"
      data-testid="show-details"
      onClick={() => setShowDetails(!showDetails)}
    />
  );
};

export const FooterAmounts: React.FC<Props> = ({
  showDetails,
  setShowDetails,
  montantALibererTTC,
  montantGele,
  openThresholdModal,
}) => {
  const isMontantALibererPositive = montantALibererTTC > 0;
  const hasMontantGele = montantGele > 0;
  const montantAvancementGlobal = montantALibererTTC + montantGele;
  const isTabletOrMobile = useIsTabletOrMobile();

  return (
    <>
      {hasMontantGele ? (
        <>
          {(!isTabletOrMobile || showDetails) && (
            <div className="flex flex-col gap-xxs pb-xs mb-xs border-b text-b2">
              <SimpleTotal
                className="font-medium"
                sectionName="Montant à libérer"
                label="Montant à libérer"
                total={montantAvancementGlobal}
              />
              <SimpleTotal
                className="font-medium"
                sectionName="Montant mis en réserve"
                label="Montant mis en réserve"
                total={-montantGele}
              />
            </div>
          )}
          <SimpleTotal
            className="font-bold text-h5"
            label={
              <div className="flex items-center">
                Total libérable{' '}
                <IconButton size="sm" onClick={openThresholdModal}>
                  <HelpCircle className="text-neutral-600" />
                </IconButton>
                <DetailsButton showDetails={showDetails} setShowDetails={setShowDetails} />
              </div>
            }
            total={montantALibererTTC > 0 ? montantALibererTTC : 0}
          />
        </>
      ) : (
        <div className="flex items-center justify-between font-bold">
          <div className="flex items-center gap-xxs">
            <div className="text-b1">Montant à libérer</div>
            <DetailsButton showDetails={showDetails} setShowDetails={setShowDetails} />
          </div>
          <EURCurrency
            className={classNames('text-h5', {
              'text-warning-800': !isMontantALibererPositive,
            })}
            amount={montantALibererTTC}
          />
        </div>
      )}
    </>
  );
};
