import React, { useEffect } from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import { Navigate, Outlet, Route, Routes, useMatch, useNavigate, useParams } from 'react-router';

import { useHandleRedirect } from '@travauxlib/shared/src/hooks/useHandleRedirect';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { useCanAccessChartePro } from 'api/profileSelectors';
import { useProfile } from 'api/useProfile';
import { useSendProfileToGtm } from 'api/useSendProfileToGtm';
import { ArchiCgvModal } from 'components/ArchiCgvModal';
import { BlockedAccountModal } from 'components/BlockedAccountModal';
import { MobileHeader } from 'components/MobileHeader/Loadable';
import { TrialPeriodBanner } from 'components/TrialPeriodBanner';
import { BaseDePrix } from 'features/BaseDePrix/Loadable';
import { BonCommande } from 'features/BonCommande/Loadable';
import { CentraleAchats } from 'features/CentraleAchats/Loadable';
import { Charte } from 'features/Charte/Loadable';
import { ConsultationOffer } from 'features/ConsultationOffer';
import { Contacts } from 'features/Contacts/Loadable';
import { Dashboard } from 'features/Dashboard/Loadable';
import { Deals } from 'features/Deals';
import { ProSideBar } from 'features/Deals/components/ProSideBar';
import { Devis } from 'features/Devis/Loadable';
import { DevisCustomization } from 'features/DevisCustomization/Loadable';
import { DevisFactures } from 'features/DevisFactures/Loadable';
import { Factures } from 'features/Factures/Loadable';
import { FactureSituation } from 'features/FactureSituation/Loadable';
import { Labellisation } from 'features/Labellisation/Loadable';
import { MonCompte } from 'features/MonCompte/Loadable';
import { ProClients } from 'features/ProClients';
import { SuiviChantier } from 'features/SuiviChantier/Loadable';
import { SuiviChantier as SuiviChantierV2 } from 'features/SuiviChantierV2';
import { useMobileSidebar } from 'hooks/useMobileSidebar';
import { useOneSignal } from 'hooks/useOneSignal';
import { useIsAllowed } from 'hooks/usePermissions';
import { dashboardUrl } from 'utils/urls';

import { NotFound } from '../../NotFoundPage';

const RoutesWithSidebarWrapper: React.FC = () => {
  const isTabletOrMobile = useIsTabletOrMobile();
  const { containerRef, isOpen, setIsOpen } = useMobileSidebar();
  const isDashboard = useMatch('/dashboard/*');
  const isAllowed = useIsAllowed('Dashboard', 'manage');
  const isNotification = useMatch('/notifications/*');
  const isOffers = useMatch('/account/parametres/abonnement/offres');
  const { profile } = useProfile();
  const trialPeriodRemainingDays = dayjs(profile.company.trialPeriodEndDate).diff(dayjs(), 'day');
  const shouldDisplayTrialPeriodBanner = profile.company.clubProStatus === 'trial_period_activated';

  return (
    <>
      {shouldDisplayTrialPeriodBanner && (
        <TrialPeriodBanner trialPeriodRemainingDays={trialPeriodRemainingDays + 1} />
      )}
      <div
        className={classNames(
          'absolute bottom-0 left-0 right-0 flex flex-col bg-white grow',
          shouldDisplayTrialPeriodBanner
            ? 'sm-desktop:top-xxl top-[6rem] sm-desktop:overflow-hidden'
            : 'top-0',
          {
            'overflow-hidden': isOpen,
          },
        )}
      >
        {isTabletOrMobile ? (
          <div className="!bg-white">
            <div ref={containerRef}>
              <MobileHeader isOpen={isOpen} setIsOpen={setIsOpen} />
              <div
                className={classNames(
                  'flex overflow-auto bg-white absolute top-[60px] h-[calc(100%-60px)] max-w-[30rem] -left-[30rem] transition-[left] duration-200 z-40',
                  {
                    'left-0 shadow-lg': isOpen,
                  },
                )}
              >
                <ProSideBar />
              </div>
            </div>
            <div
              className={classNames('p-md', {
                'pointer-events-none': isOpen,
                'bg-neutral-100': (isAllowed && isDashboard) || isNotification || isOffers,
              })}
            >
              <Outlet />
            </div>
          </div>
        ) : (
          <div className="!flex !h-full">
            <ProSideBar />
            <div
              className={classNames(
                'relative flex-grow overflow-auto p-xmd',
                (isAllowed && isDashboard) || isNotification || isOffers
                  ? 'bg-neutral-100'
                  : 'bg-white',
              )}
            >
              <div className={classNames(isOffers && 'max-w-[1500px] mx-auto')}>
                <Outlet />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const ConsultationOfferRedirect: React.FC = () => {
  const { token } = useParams();

  return <Navigate replace to={`/deals/consultation-offers/${token}`} />;
};

export const AuthenticatedRoutes: React.FC = () => {
  const { profile } = useProfile();
  const navigate = useNavigate();

  const canAccessChartePro = useCanAccessChartePro();
  const shouldDisplayChartePro =
    canAccessChartePro && !profile.company.charteStatus && !profile.isHemeaEmployee;

  useHandleRedirect();
  useOneSignal();
  useSendProfileToGtm();

  useEffect(() => {
    if (shouldDisplayChartePro) {
      navigate('/charte', { replace: true });
    }
  }, [shouldDisplayChartePro]);

  return (
    <>
      <BlockedAccountModal />
      <ArchiCgvModal />
      <Routes>
        <Route path="" element={<Navigate replace to={dashboardUrl} />} />
        <Route path="login/*" element={<Navigate replace to={dashboardUrl} />} />
        <Route path="reset-password/*" element={<Navigate replace to={dashboardUrl} />} />
        <Route path="registration/*" element={<Navigate replace to={dashboardUrl} />} />
        <Route path="deals/:uuid/factures/:token/*" element={<Factures />} />
        <Route path="deals/:uuid/devis/:token" element={<Devis.Index />}>
          <Route path="prestations" element={<Devis.Devis />} />
          <Route path="ts" element={<Devis.DevisTS />} />
          <Route path="envoi" element={<Devis.SendDevisEmail />} />
          <Route
            path="envoi/missing-dates"
            element={<Devis.SendDevisEmail areChantierDatesRequired={true} />}
          />
          <Route path="export" element={<Devis.Export />} />
        </Route>
        <Route path="deals/:uuid/suivi-chantier/:token" element={<SuiviChantier />} />
        <Route path="deals/:uuid/suivi-chantier-v2" element={<SuiviChantierV2.Index />}>
          <Route path="" element={<SuiviChantierV2.MainSuivi />} />
          <Route path=":firstLotUuidInGroup" element={<SuiviChantierV2.LotAvancement />} />
        </Route>
        <Route path="deals/:uuid/facture-situation/:token" element={<FactureSituation />} />
        <Route path="deals/:uuid/bon-commande/:token" element={<BonCommande />} />
        <Route path="devis-personnalisation" element={<DevisCustomization />} />
        <Route path="*" element={<RoutesWithSidebarWrapper />}>
          <Route path="contacts" element={<Contacts.Index />}>
            <Route path="clients" element={<Contacts.Clients />} />
            <Route path="prestataires" element={<Contacts.Contractors />} />
            <Route path="*" element={<Navigate replace to="clients" />} />
          </Route>
          <Route path="charte" element={<Charte />} />
          <Route path="base-de-prix" element={<BaseDePrix.Index />}>
            <Route path="main-oeuvre" element={<BaseDePrix.MainOeuvreList />} />
            <Route path="materiau" element={<BaseDePrix.MateriauList />} />
            <Route path="fourniture" element={<BaseDePrix.FournitureList />} />
            <Route path="" element={<BaseDePrix.BaseDePrix />} />
          </Route>
          <Route path=":itemType" element={<DevisFactures />} />
          <Route path="account">
            <Route path="*" element={<MonCompte />} />
          </Route>
          <Route path="centrale-achat">
            <Route path="*" element={<CentraleAchats />} />
          </Route>
          <Route path="labellisation" element={<Labellisation />} />
          <Route path="deals/consultation-offers/:token" element={<ConsultationOffer />} />
          <Route path="deals/offres-chantier/:token" element={<ConsultationOfferRedirect />} />
          <Route path="deals/:uuid">
            <Route path="*" element={<Deals />} />
          </Route>
          <Route path="dashboard" element={<Dashboard.Index />}>
            <Route path="devis" element={<Dashboard.DevisRecap />} />
            <Route path="factures" element={<Dashboard.FacturesRecap />} />
            <Route path="chantiers" element={<Dashboard.ChantiersRecap />} />
            <Route path="*" element={<Navigate replace to="devis" />} />
          </Route>
          <Route path="clients/:uuid">
            <Route path="*" element={<ProClients />} />
          </Route>
          <Route
            path="deals/:uuid/suivi-chantier-v2/:suiviChantierUuid/history"
            element={<SuiviChantierV2.SuiviChantierHistory />}
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
};
