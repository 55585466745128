import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { DownloadableFile } from '@travauxlib/shared/src/types';
import { ChantierPvType } from '@travauxlib/shared/src/types/api/common/ChantierPvView';
import { request } from '@travauxlib/shared/src/utils/request';

import { DEAL_CHANTIER_KEY } from 'features/Chantiers/api/useDealChantier';

type Payload = {
  chantierUuid: string;
  documents: DownloadableFile[];
  pvType: ChantierPvType;
};

const handleUploadPv = ({ chantierUuid, ...body }: Payload): Promise<void> => {
  const requestURL = `${APP_CONFIG.proApiURL}/chantiers/${chantierUuid}/upload-pv`;
  return request(requestURL, {
    method: 'POST',
    body,
  });
};

export const useUploadPv = (): { uploadPv: typeof handleUploadPv; isLoading: boolean } => {
  const queryClient = useQueryClient();
  const { mutateAsync, isPending } = useMutation({
    mutationFn: handleUploadPv,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [DEAL_CHANTIER_KEY] });
    },
    onError: () => {
      toast.error("L'ajout du PV a échoué");
    },
  });
  return { uploadPv: mutateAsync, isLoading: isPending };
};
