import _groupBy from 'lodash/groupBy';

import { ProDevisView } from '@travauxlib/shared/src/types/api/pro/ProDevisView';

import { Facture } from 'types';

type DevisAndFactures = {
  devis?: ProDevisView;
  factures: Facture[];
};

export const generateFacturesListByDevis = (
  signedDevisList: ProDevisView[],
  facturesList: Facture[],
): DevisAndFactures[] => {
  const facturesByDevisToken = _groupBy(facturesList, f => f.proDevisToken);

  const devisAndFacturesList: DevisAndFactures[] = signedDevisList.map(devis => ({
    devis,
    factures: facturesByDevisToken[devis.token] || [],
  }));

  const devisAndFacturesListWithIndependant = [
    ...devisAndFacturesList,
    { factures: facturesByDevisToken['undefined'] || [] },
  ];

  return devisAndFacturesListWithIndependant.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    if (!a.devis) {
      return 1;
    }

    if (!b.devis) {
      return -1;
    }

    return a.devis.createdAt < b.devis.createdAt ? 1 : -1;
  });
};
