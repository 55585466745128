import { DealView } from '@travauxlib/shared/src/types/api/pro/DealView';

import { useArchiType } from 'api/profileSelectors';

export const useShouldShowFeedbackQuestions = (deal: DealView): boolean => {
  const { typePrestationArchi, feedbackRDV, chaleurDeal, meeting, isHemea, status } = deal;
  const archiType = useArchiType();

  const isFeedbackDone =
    feedbackRDV !== undefined &&
    ((archiType && typePrestationArchi) || (!archiType && chaleurDeal !== undefined));

  return !isFeedbackDone && !!meeting?.hasOccurred && isHemea && status === 'open';
};
