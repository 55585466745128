import React from 'react';

import _groupBy from 'lodash/groupBy';
import { FormSpy, useForm } from 'react-final-form';

import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { SimpleDealView } from '@travauxlib/shared/src/types/api/admin/SimpleProClientView';
import { PartialDealView } from '@travauxlib/shared/src/types/api/pro/PartialProClientView';
import { ProDevisView } from '@travauxlib/shared/src/types/api/pro/ProDevisView';
import { required } from '@travauxlib/shared/src/utils/form';

import { HybridProClient } from 'types';

type Props = {
  deals: (PartialDealView | SimpleDealView)[];
  devisList: ProDevisView[];
  proClients: HybridProClient[];
  label: string;
};

const getChantierOptions = (
  devisList: ProDevisView[],
): Array<{ value: string | boolean; label: string }> => {
  const groupedDevisList = _groupBy(devisList, devis => devis.projectUuid);

  return [
    ...Object.entries<ProDevisView[]>(groupedDevisList).map(([projectUuid, devisList]) => ({
      value: projectUuid,
      label: devisList[0].projectTitle,
    })),
    { value: false, label: 'Nouveau devis' },
  ];
};

export const ProClientAndDealSelector: React.FC<Props> = ({
  deals,
  devisList,
  label,
  proClients,
}) => {
  const form = useForm();

  return (
    <>
      <div className="mb-md">
        <DropdownField
          name="proClientUuid"
          id="pro-client-uuid"
          label={label}
          options={proClients.map(proClient => ({
            value: proClient.uuid,
            label: proClient.name,
          }))}
          validate={required}
          fixedFloating={false}
        />
      </div>
      {deals.length === 1 ? (
        <FormSpy
          subscription={{ values: true }}
          onChange={() => {
            setTimeout(() => {
              form.change('newDealUuid', deals[0].uuid);
            }, 0);
          }}
        />
      ) : (
        <div className="mb-md">
          <DropdownField
            name="newDealUuid"
            id="new-deal-uuid"
            label="Chantiers parmi ce client"
            options={deals.map(deal => ({ value: deal.uuid, label: deal.title }))}
            validate={required}
            fixedFloating={false}
          />
        </div>
      )}
      <div className="mb-xl">
        <DropdownField
          name="newProjectUuid"
          id="new-project-uid"
          label={`Devis parmi ce ${deals.length > 1 ? 'chantier' : 'client'}`}
          options={getChantierOptions(devisList)}
          validate={required}
          fixedFloating={false}
        />
      </div>
    </>
  );
};
