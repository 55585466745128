import React from 'react';

import { Field } from 'react-final-form';

import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { ModalitePaiementView } from '@travauxlib/shared/src/types/api/common/ModalitePaiementView';
import { computeTotalPourcentageModalites } from '@travauxlib/shared/src/utils/computeTotalPourcentageModalites';

import { ModalitesComponent } from './ModalitesComponent';

type Props = {
  canEditModalites: boolean;
  prixTotalTTC: number;
  saveModalitesPaiement?: boolean;
  setSaveModalitesPaiement?: (saveModalitesPaiement: boolean) => void;
};

export const Modalites: React.FC<Props> = ({
  canEditModalites,
  prixTotalTTC,
  saveModalitesPaiement = false,
  setSaveModalitesPaiement,
}) => (
  <div className="!mb-xmd">
    <Field
      name="modalitesPaiement"
      validate={(modalites: ModalitePaiementView[]) => {
        const totalPercentage = computeTotalPourcentageModalites(modalites);
        if (totalPercentage !== 100) {
          return 'Le total doit être égal à 100 %.';
        }
        if (modalites.some(({ label }) => !label.trim())) {
          return 'Les échéances doivent comporter un libellé.';
        }
        return undefined;
      }}
      render={({ meta, input: { onBlur, onChange, value } }) => (
        <ModalitesComponent
          prixTotalTTC={prixTotalTTC}
          onBlur={onBlur}
          onChange={onChange}
          modalites={value}
          canEditModalites={canEditModalites}
          error={meta.touched && meta.error ? meta.error : undefined}
        />
      )}
    />
    {setSaveModalitesPaiement && (
      <div className="pt-md">
        <Checkbox
          id="saveModalitesPaiement"
          checked={saveModalitesPaiement}
          onChange={setSaveModalitesPaiement}
          label="Conserver ces modalités de paiements pour mes prochains devis"
        />
      </div>
    )}
  </div>
);
