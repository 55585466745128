import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { GoogleMapsAddress } from '@travauxlib/shared/src/types/api/common/GoogleMapsAddress';
import { ManualAddress } from '@travauxlib/shared/src/types/api/common/ManualAddress';
import { request } from '@travauxlib/shared/src/utils/request';

import { ProClient } from 'types';

import { PRO_CLIENTS_KEY } from './useProClients';

export type CreateProClientView = {
  uuid?: string;
  chantierAddress?: ManualAddress;
  chantierGoogleMapsAddress?: GoogleMapsAddress;
  chantierAddressComplement?: string;
  billingAddress?: ManualAddress;
  billingGoogleMapsAddress?: GoogleMapsAddress;
  billingAddressComplement?: string;
  name: string;
  dealTitle?: string;
  phoneNumber?: string;
  email?: string;
  sameBillingAndChantierAddress: boolean;
  sendEmail?: boolean;
};

const handleCreateProClient = (proClient: CreateProClientView): Promise<ProClient> => {
  const requestURL = `${APP_CONFIG.proApiURL}/clients`;
  return request(requestURL, {
    method: 'POST',
    body: proClient,
  });
};

export const useCreateProClient = (): typeof handleCreateProClient => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutateAsync: createProClient } = useMutation({
    mutationFn: handleCreateProClient,
    onSuccess: (result: ProClient) => {
      navigate(`/clients/${result.uuid}`);
      queryClient.invalidateQueries({ queryKey: [PRO_CLIENTS_KEY] });
    },
    onError: () => {
      toast.error("Le client n'a pas pu être créé");
    },
  });

  return createProClient;
};
