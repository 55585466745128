import React from 'react';

import classNames from 'classnames';
import { useNavigate } from 'react-router';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { BonCommande } from '@travauxlib/shared/src/types';

import { getBonCommandePreviewUrl, getBonCommandeRootUrl } from 'utils/urls';

import { BonCommandeActions } from './BonCommandeActions';
import { Date } from './Date';
import { Status } from './Status';

type Props = {
  bonCommande: BonCommande;
};

export const BonCommandeCard: React.FC<Props> = ({ bonCommande }) => {
  const navigate = useNavigate();
  const bonCommandeLink = getBonCommandeRootUrl(bonCommande);

  return (
    <Card
      className={classNames('rounded-md mb-xs cursor-pointer bg-primarylighter', {
        '!bg-lighter': bonCommande.archived,
      })}
      onClick={() => {
        if (bonCommande.status === 'sent') {
          return window.open(getBonCommandePreviewUrl(bonCommande));
        }
        navigate(bonCommandeLink);
      }}
    >
      <>
        <div className="flex justify-between items-center mb-md">
          <div className="font-bold">{bonCommande.title}</div>
          <div className="flex" onClick={event => event.stopPropagation()}>
            <BonCommandeActions bonCommande={bonCommande} />
          </div>
        </div>
        <div className="mb-md font-bold">
          <EURCurrency amount={bonCommande.prixTotalHT} />
        </div>
        <div className="flex justify-between flex-wrap">
          <div className="mt-xs">
            <Date bonCommande={bonCommande} />
          </div>
          <div className="mt-xs">
            <Status status={bonCommande.status} />
          </div>
        </div>
      </>
    </Card>
  );
};
