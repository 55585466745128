import React from 'react';

import { ChantierPvType } from '@travauxlib/shared/src/types/api/common/ChantierPvView';
import { ChantierProView } from '@travauxlib/shared/src/types/api/pro/ChantierProView';

import { PvBloc } from './components/PvBloc';

type Props = {
  chantier: ChantierProView;
};

export const PvChantier: React.FC<Props> = ({ chantier }) => {
  const hasReserves = chantier.pv.receptionChantier?.hasReserves;
  const hideLeveeReserves = !hasReserves && chantier.pv.receptionChantier?.isValidated;

  return (
    <div className="rounded-md border shadow-xl p-md tablet:p-lg bg-white mb-md">
      <div className="font-bold text-h5 mb-xxs">Documents de fin de chantier</div>
      <div className="text-neutral-600 text-b2 mb-md">
        Ajouter ici les procès-verbaux de{' '}
        <span className="italic text-neutral-700">“Réception de chantier”</span> et de{' '}
        <span className="italic text-neutral-700">“Levée des réserves”</span> pour les envoyer à
        votre client afin de terminer votre chantier et d’obtenir le solde du montant en attente.
      </div>
      <PvBloc
        pv={chantier.pv.receptionChantier}
        chantierUuid={chantier.uuid}
        pvType={ChantierPvType.ReceptionChantier}
      />
      {!hideLeveeReserves && (
        <>
          <div className="h-[1px] bg-neutral-300" />
          <PvBloc
            pv={chantier.pv.leveeReserves}
            chantierUuid={chantier.uuid}
            pvType={ChantierPvType.LeveeReserves}
            disabledReason={
              chantier.pv?.receptionChantier?.hasReserves
                ? undefined
                : 'Vous ne pouvez pas ajouter le procès-verbal de “Levée des réserves" tant que le procès-verbal de “Réception de chantier" n\'a pas été validé par le client'
            }
          />
        </>
      )}
    </div>
  );
};
