import React from 'react';

import Archive from '@travauxlib/shared/src/components/DesignSystem/assets/Archive.svg?react';
import DocumentFilled from '@travauxlib/shared/src/components/DesignSystem/assets/DocumentFilled.svg?react';
import Picture from '@travauxlib/shared/src/components/DesignSystem/assets/PictureFilled.svg?react';
import Tva from '@travauxlib/shared/src/components/DesignSystem/assets/Tva.svg?react';

export const getFileIconByUrl = (url?: string): React.ReactElement => {
  const ext = url?.substr(url.lastIndexOf('.') + 1)?.toLowerCase();

  if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gif' || ext === 'bmp') {
    return <Picture className="w-md h-md" />;
  }
  if (ext === 'xls' || ext === 'xlsx') {
    return <Tva className="w-md h-md" />;
  }
  if (ext === 'zip' || ext === 'rar') {
    return <Archive className="w-md h-md" />;
  }

  return <DocumentFilled className="w-md h-md" />;
};
