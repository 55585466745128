import { useEffect } from 'react';

import OneSignal from 'react-onesignal';

import { useProfile } from '../api/useProfile';
import { useUpdateProfile } from '../api/useUpdateProfile';

export const useOneSignal = (): void => {
  const { profile } = useProfile();
  const { updateProfile } = useUpdateProfile(true);

  useEffect(() => {
    OneSignal.init({
      appId: APP_CONFIG.oneSignal.appId,
      safari_web_id: APP_CONFIG.oneSignal.safariWebId,
      notifyButton: {
        enable: false,
      },
      allowLocalhostAsSecureOrigin: true,
      welcomeNotification: {
        title: 'Merci pour votre souscription',
        message: 'Bienvenue !',
      },
    }).then(async () => {
      const hasAlreadySubscribed = await OneSignal.getSubscription();
      const newWebPushId = await OneSignal.getUserId();
      const permission = await OneSignal.getNotificationPermission();

      if (hasAlreadySubscribed && permission === 'granted') {
        await OneSignal.registerForPushNotifications();

        OneSignal.sendTags({
          isClubProEssentiel: profile?.company.isClubProEssentiel,
          isClubProPlus: profile?.company.isClubProPlus,
          isClubProPremium: profile?.company.isClubProLegacyPremium,
        });
        if (newWebPushId && !profile.webPushIds?.includes(newWebPushId)) {
          updateProfile({
            ...profile,
            isWebPushEnabled: profile.isWebPushEnabled,
            webPushId: newWebPushId,
          });
        }
      }
    });
  }, []);
};
