import React, { useState } from 'react';

import _orderBy from 'lodash/orderBy';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { useIsBelowBreakpoint } from '@travauxlib/shared/src/hooks/useIsBelowBreakpoint';
import { ProDevisView } from '@travauxlib/shared/src/types/api/pro/ProDevisView';
import { devisOrProposition } from '@travauxlib/shared/src/utils/wording';

import { DevisCard } from 'features/Deals/features/Devis/components/DevisList/components/DevisCard';
import { DevisRow } from 'features/Deals/features/Devis/components/DevisList/components/DevisTable/DevisRow';
import { DevisTableHeader } from 'features/Deals/features/Devis/components/DevisList/components/DevisTable/DevisTableHeader';

export type SortedBy = {
  columnName: string;
  order: 'asc' | 'desc';
};

type Props = {
  devisToDisplay: ProDevisView[];
  isArchitecte: boolean;
  isHemea: boolean;
};

export const DevisList = ({ devisToDisplay, isArchitecte, isHemea }: Props): React.ReactElement => {
  const [sortedBy, setSortedBy] = useState<SortedBy>({
    columnName: 'lastModifiedAt',
    order: 'desc',
  });
  const [devisShowed, setDevisShowed] = useState<number | undefined>(5);
  const isBelowSmallDesktop = useIsBelowBreakpoint('sm-desktop');

  const sortedDevisList = _orderBy(devisToDisplay, sortedBy.columnName, sortedBy.order).filter(
    (_, index) => !devisShowed || index < devisShowed,
  );

  return (
    <>
      {isBelowSmallDesktop ? (
        sortedDevisList.map(devis => (
          <DevisCard key={devis.token} devis={devis} isHemea={isHemea} />
        ))
      ) : (
        <table className="table border-separate border-spacing-0 mb-xs">
          <thead>
            <DevisTableHeader
              sortedBy={sortedBy}
              setSortedBy={setSortedBy}
              isArchitecte={isArchitecte}
            />
          </thead>
          <tbody>
            {sortedDevisList.map(devis => (
              <React.Fragment key={devis.token}>
                <tr className="h-xs" />
                <DevisRow key={devis.token} devis={devis} isHemea={isHemea} />
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )}
      {devisShowed && devisToDisplay.length > devisShowed && (
        <Button
          type="button"
          className="my-xs"
          variant="tertiary"
          fullwidth
          onClick={() => setDevisShowed(undefined)}
        >
          Voir l'ensemble des versions {devisOrProposition({ isArchitecte, prefix: 'du' })}
        </Button>
      )}
    </>
  );
};
