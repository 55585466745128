import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { DealView } from '@travauxlib/shared/src/types/api/pro/DealView';
import { request } from '@travauxlib/shared/src/utils/request';

import { DEAL_KEY } from './useDeal';

const handleCancelDateRDV = ({
  dealUuid,
  meetingUuid,
}: {
  dealUuid: string;
  meetingUuid: string;
}): Promise<DealView> => {
  const requestURL = `${APP_CONFIG.proApiURL}/deals/${dealUuid}/meetings/${meetingUuid}`;
  return request(requestURL, {
    method: 'DELETE',
  });
};

export const useCancelDateRDV = (): {
  cancelDateRDV: typeof handleCancelDateRDV;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();
  const { mutateAsync: cancelDateRDV, isPending } = useMutation({
    mutationFn: handleCancelDateRDV,
    onSuccess: (deal: DealView) => {
      queryClient.setQueryData([DEAL_KEY, deal.uuid], deal);

      toast.success('Le rendez-vous a bien été annulé');
    },
  });
  return { cancelDateRDV, isLoading: isPending };
};
