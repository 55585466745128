import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { ProDevisView } from '@travauxlib/shared/src/types/api/pro/ProDevisView';
import { request } from '@travauxlib/shared/src/utils/request';

import { HybridDevis } from 'types';

const handleCreateTemplate = (devis: HybridDevis): Promise<void> => {
  const requestURL = `${APP_CONFIG.proApiURL}/user-devis-templates`;
  return request(requestURL, {
    method: 'POST',
    body: devis,
  });
};

const handleRemoveTemplate = (token: string): Promise<void> => {
  const requestURL = `${APP_CONFIG.proApiURL}/user-devis-templates/${token}`;
  return request(requestURL, {
    method: 'DELETE',
  });
};

const USER_DEVIS_TEMPLATES = 'user-devis-templates';

export const useUserTemplatesList = (): {
  create: typeof handleCreateTemplate;
  deleteTemplate: typeof handleRemoveTemplate;
  devisUserTemplatesItems: ProDevisView[];
  isLoaded: boolean;
} => {
  const { data, isLoading } = useQuery<{ data: ProDevisView[] }>({
    queryKey: [USER_DEVIS_TEMPLATES],
    queryFn: (): Promise<{ data: ProDevisView[] }> => {
      const requestURL = `${APP_CONFIG.proApiURL}/user-devis-templates`;
      return request(requestURL);
    },
  });

  const queryClient = useQueryClient();

  const { mutateAsync: create } = useMutation({
    mutationFn: handleCreateTemplate,
    onSuccess: () => {
      toast.success('Le modèle a bien été créé');
      queryClient.invalidateQueries({ queryKey: [USER_DEVIS_TEMPLATES] });
    },
    onError: () => {
      toast.error("Le modèle n'a pas pu être créé");
    },
  });

  const { mutateAsync: deleteTemplate } = useMutation({
    mutationFn: handleRemoveTemplate,
    onSuccess: () => {
      toast.success('Le modèle a bien été supprimé');
      queryClient.invalidateQueries({ queryKey: [USER_DEVIS_TEMPLATES] });
    },
    onError: () => {
      toast.error("Le modèle n'a pas pu être supprimé");
    },
  });

  return {
    create,
    deleteTemplate,
    isLoaded: !isLoading,
    devisUserTemplatesItems: data?.data || [],
  };
};
