import { BonCommande, FactureStatuses } from '@travauxlib/shared/src/types';
import { parametresBaseUrl } from '@travauxlib/shared/src/utils/urls';

import { Facture } from 'types';
import { HybridDevis } from 'types';

type TokenAndDealUuid = {
  dealUuid: string;
  token: string;
};

export const baseDePrixUrl = '/base-de-prix';
export const mainOeuvreUrl = `${baseDePrixUrl}/main-oeuvre`;
export const materiauUrl = `${baseDePrixUrl}/materiau`;
export const fournitureUrl = `${baseDePrixUrl}/fourniture`;

export const dealsUrl = '/deals';
export const proClientsUrl = '/clients';

export const getProClientRootUrl = (proClientUuid: string): string =>
  `${proClientsUrl}/${proClientUuid}`;
export const getDealRootUrl = (dealUuid: string): string => `${dealsUrl}/${dealUuid}`;

export const getDealConfigurationUrl = (dealUuid: string): string =>
  `${getDealRootUrl(dealUuid)}/dossier-consultation`;

export const getDealFacturesUrl = (dealUuid: string): string => `/deals/${dealUuid}/factures`;

export const getDevisRootUrl = (devis: TokenAndDealUuid): string =>
  `/deals/${devis.dealUuid}/devis/${devis.token}`;

export const getSuiviChantierRootUrl = (suivi: TokenAndDealUuid): string =>
  `/deals/${suivi.dealUuid}/suivi-chantier/${suivi.token}`;

export const getBonCommandeRootUrl = (bonCommande: TokenAndDealUuid): string =>
  `/deals/${bonCommande.dealUuid}/bon-commande/${bonCommande.token}`;

export const getPrintBonCommandeUrl = (bonCommande: TokenAndDealUuid): string =>
  `${APP_CONFIG.apiURL}/pro/deals/${bonCommande.dealUuid}/bon-commande/${bonCommande.token}/pdf`;

export const getBonCommandeMenuRootUrl = (dealUuid: string): string =>
  `/deals/${dealUuid}/bon-commande`;

export const getDevisPrestationsUrl = (devis: HybridDevis): string => {
  const isStatusToBeRevised = devis.status === 'to_be_revised';

  const shouldDisplayLocations = devis.isFromConfiguration && !isStatusToBeRevised;
  return `${getDevisRootUrl(devis)}/prestations?${shouldDisplayLocations ? 'locations' : 'lots'}`;
};

export const getDevisTSUrl = (devis: TokenAndDealUuid): string => `${getDevisRootUrl(devis)}/ts`;

export const getDevisEnvoiUrl = (devis: TokenAndDealUuid): string =>
  `${getDevisRootUrl(devis)}/envoi`;

export const getDevisExportUrl = (devis: TokenAndDealUuid): string =>
  `${getDevisRootUrl(devis)}/export`;

export const getLinkDevisRO = (devis: HybridDevis): string =>
  `${APP_CONFIG.appURL}/pro/devis/${devis.token}?isAuthor`;

export const getBonCommandePreviewUrl = (bonCommande: BonCommande): string =>
  `${APP_CONFIG.appURL}/pro/bon-commande/${bonCommande.token}?isAuthor`;

export const getLinkDevisIframeRO = (devis: HybridDevis): string =>
  `${APP_CONFIG.appURL}/pro/devis/${devis.token}/iframe?isAuthor`;

export const getDevisPdfUrl = (devis: HybridDevis): string =>
  `${APP_CONFIG.apiURL}/devis-pro/${devis.token}/pdf`;

export const getFactureRO = (facture: Facture): string =>
  `${APP_CONFIG.appURL}/factures/${facture.token}?isAuthor`;

export const getFactureUrl = (facture: Facture): string =>
  facture.status === FactureStatuses.draft && !facture.archived
    ? `/deals/${facture.dealUuid}/factures/${facture.token}`
    : getFactureRO(facture);

export const getDevisUrl = (devis: HybridDevis): string =>
  ['sent', 'signed', 'sent_hemea', 'rejected_hemea'].includes(devis.status)
    ? getLinkDevisRO(devis)
    : devis.isTS
      ? getDevisTSUrl(devis)
      : getDevisPrestationsUrl(devis);

export const getFactureSituationUrl = (devis: HybridDevis): string =>
  `/deals/${devis.dealUuid}/facture-situation/${devis.token}`;

export const dashboardUrl = '/dashboard';
export const dashboardDevisRecapUrl = `${dashboardUrl}/devis`;
export const allDevisAndFacturesUrl = '/devis';
export const allFacturesUrl = '/factures';

export const partenariatRootUrl = '/centrale-achat';

export const partenariatMateriauxUrl = `${partenariatRootUrl}/materiaux`;
export const partenariatCarsUrl = `${partenariatRootUrl}/vehicules-utilitaires`;
export const partenariatAssurancesUrl = `${partenariatRootUrl}/assurances`;
export const partenariatCEUrl = `${partenariatRootUrl}/ce`;
export const partenariatDTUUrl = `${partenariatRootUrl}/assistance-technique-legale`;
export const partenariatFormationUrl = `${partenariatRootUrl}/formations`;
export const partenariatSupportAdministratifUrl = `${partenariatRootUrl}/support-administratif`;
export const partenariatServicesUrl = `${partenariatRootUrl}/services`;

export const loginUrl = '/login';
export const registrationUrl = '/registration';
export const forgotPasswordUrl = '/forgot-password';
export const devisCustomizationUrl = '/devis-personnalisation';

export const contactsUrl = '/contacts';
export const clientsUrl = `${contactsUrl}/clients`;
export const contractorsUrl = `${contactsUrl}/prestataires`;

export const partenariatWebsiteUrl = `${APP_CONFIG.wwwURL}/fr/pro/parrainage-clubpro`;

export const parametresProfileUrl = `${parametresBaseUrl}/profile`;
export const parametresEntrepriseUrl = `${parametresBaseUrl}/entreprise`;
export const parametresUtilisateursUrl = `${parametresBaseUrl}/utilisateurs`;
export const parametresLabellisationUrl = `${parametresBaseUrl}/labellisation`;
export const parametresConditionsUrl = `${parametresBaseUrl}/conditions`;

export const parametresAbonnementUrl = `${parametresBaseUrl}/abonnement`;
export const parametresAbonnementPlanUrl = `${parametresAbonnementUrl}/offres`;
export const parametresAbonnementPaymentUrl = `${parametresAbonnementUrl}/payment`;

export const clubProEssentielMonthlyUrl = `${parametresAbonnementPlanUrl}/essentiel_monthly`;
export const clubProEssentielAnnualUrl = `${parametresAbonnementPlanUrl}/essentiel_annually`;
export const clubProProMonthlyUrl = `${parametresAbonnementPlanUrl}/pro_monthly`;
export const clubProProAnnualUrl = `${parametresAbonnementPlanUrl}/pro_annually`;
