import React from 'react';

import { Navigate, Route, Routes } from 'react-router';

import { Configuration } from '@travauxlib/shared/src/types/api/domain/configurateur';
import { ChantierProView } from '@travauxlib/shared/src/types/api/pro/ChantierProView';
import { DealView } from '@travauxlib/shared/src/types/api/pro/DealView';

import { BonCommandeList } from 'features/BonCommande/components/BonCommandeList';
import { ChantierDetails } from 'features/Chantiers';
import { Fournitures } from 'features/Fournitures';
import { SuiviChantier } from 'features/SuiviChantier';

import { MessagesList } from '../components/MessagesList';
import { DevisComponent } from '../features/Devis';
import { EstimationDetails } from '../features/Estimation';
import { Factures } from '../features/Factures';

type Props = {
  chantier?: ChantierProView;
  deal: DealView;
  configuration?: Configuration;
};

export const DealsRoutes: React.FC<Props> = ({ chantier, deal, configuration }) => (
  <Routes>
    {chantier && (
      <>
        <Route path="chantier" element={<ChantierDetails chantier={chantier} />} />
        <Route path="suivi-chantier" element={<SuiviChantier />} />
      </>
    )}
    {configuration && (
      <Route
        path="dossier-consultation"
        element={<EstimationDetails files={deal.files} configuration={configuration} />}
      />
    )}
    <Route
      path="devis"
      element={<DevisComponent dealUuid={deal.uuid} isDealFromHemea={deal.isHemea} />}
    />

    <Route path="fournitures" element={<Fournitures dealUuid={deal.uuid} />} />
    <Route path="factures" element={<Factures dealUuid={deal.uuid} />} />
    <Route path="messages" element={<MessagesList deal={deal} />} />
    <Route path="bon-commande" element={<BonCommandeList dealUuid={deal.uuid} />} />
    <Route path="*" element={<Navigate replace to={chantier ? 'chantier' : 'devis'} />} />
  </Routes>
);
