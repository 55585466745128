import React from 'react';

import { Form } from 'react-final-form';
import { useNavigate } from 'react-router';

import {
  useOpenModal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { RadioButtonsListField } from '@travauxlib/shared/src/components/DesignSystem/components/RadioButtonsList/Field';

import { useCreateAcompte } from 'features/Factures/api/useCreateAcompte';
import { HybridDevis } from 'types';
import { FactureTracking } from 'utils/tracking/FactureTracking';
import { getFactureUrl } from 'utils/urls';

type Props = {
  handleClose: () => void;
  devis: HybridDevis;
};

const DEFAULT_PERCENTAGE_REMISE = 40;

type FormValues = {
  typeAcompte: 'Pourcentage' | 'Montant';
  montantFacture: number;
  pourcentageFacture: number;
};

export const Acompte: React.FC<Props> = ({ handleClose, devis }) => {
  const navigate = useNavigate();
  const createAcompte = useCreateAcompte();
  const acompte = devis.modalitesPaiement[0] || {
    montant: (devis.prixTotalTTC * DEFAULT_PERCENTAGE_REMISE) / 100,
    pourcentage: DEFAULT_PERCENTAGE_REMISE,
  };

  const handleSubmit = async (values: FormValues): Promise<void> => {
    const facture = await createAcompte({
      factureAcompteInfo: {
        ...values,
        proDevisToken: devis.token,
      },
      dealUuid: devis.dealUuid,
    });
    FactureTracking.onFactureCreated({
      type: 'Acompte',
      factureToken: facture.token,
      devisToken: facture.proDevisToken,
    });
    navigate(getFactureUrl(facture));
  };

  return (
    <Form<FormValues>
      initialValues={
        {
          montantFacture: acompte.montant,
          pourcentageFacture: acompte.pourcentage,
        } as FormValues
      }
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, values: { typeAcompte } }) => (
        <ModalContent
          handleSubmit={async values => {
            await handleSubmit(values);
            handleClose();
          }}
          validateAction={{
            label: 'Créer la facture',
            disabled: typeAcompte === undefined,
            type: 'submit',
          }}
        >
          <RadioButtonsListField
            className="mb-md"
            name="typeAcompte"
            options={[
              { label: 'Pourcentage', value: 'Pourcentage' },
              { label: 'Montant', value: 'Montant' },
            ]}
          />
          {typeAcompte === 'Pourcentage' && (
            <NumberInputField
              name="pourcentageFacture"
              suffix="%"
              label="Pourcentage"
              min={0}
              max={100}
              id="pourcentageFactureInput"
              data-testid="pourcentageFactureInput"
            />
          )}
          {typeAcompte === 'Montant' && (
            <NumberInputField
              id="montantFacture"
              name="montantFacture"
              label="Montant"
              suffix="€"
              data-testid="montantFactureInput"
            />
          )}
        </ModalContent>
      )}
    </Form>
  );
};

export const useOpenFacturerAcompteModal = (): ((devis: HybridDevis) => void) => {
  const openModal = useOpenModal();
  return (devis: HybridDevis) =>
    openModal(Acompte, {
      title: "Facture d'acompte",
      closable: true,
      devis,
    });
};
