import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import {
  ClubProFeatures,
  ProEventsProperties,
  TrackingEvents,
} from '@travauxlib/shared/src/utils/tracking';

class ListeFournituresTrackingClass extends Tracker<ClubProFeatures> {
  onFournituresListOpened = (properties: { [ProEventsProperties.DealUuid]: string }): void => {
    this.sendTracking({
      event: TrackingEvents.Opened,
      properties,
    });
  };
}

export const ListeFournituresTracking = new ListeFournituresTrackingClass(
  ClubProFeatures.ListeFournitures,
);
