import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { ProClient } from 'types';

import { ModalProClientDetailsUnprotected } from '../components/ClientDetails/ModalProClientDetails';

// YOU SHALL NOT PASS
// Ah bah en fait si tu peux passer vu que ce composant est utilisé uniquement une
// fois qu'on a vérifié que le pro a PAYÉ ou qu'il est en période d'essai.
// Ça peut te sembler être un doublon mais TU TE TROMPES il permet d'éviter un import cyclique entre
// ClubProPaywallModal > useOpenProClientDetailsModal > WithClubPro > ClubProPaywallModal
// |
// |
// FLY YOU FOOLS !
export const useOpenUnprotectedProClientDetailsModal = (): ((proClient?: ProClient) => void) => {
  const openModal = useOpenModal();
  const isTabletOrMobile = useIsTabletOrMobile();
  return (proClient?: ProClient): void => {
    openModal(ModalProClientDetailsUnprotected, {
      title: 'Informations du client',
      proClient,
      closable: true,
      isScrollable: isTabletOrMobile,
    });
  };
};
