import React from 'react';

import { InsuranceForm } from '@travauxlib/shared/src/components/InsuranceForm';
import { InsuranceFormType } from '@travauxlib/shared/src/utils/constants';

import { useUploadInsurance } from 'api/useUploadInsurance';
import { useUploadTempS3Files } from 'api/useUploadTempS3Files';
import { ProCompany } from 'types';

export const RCProInsuranceForm: React.FC<{ company: ProCompany; fixedFloating?: boolean }> = ({
  company,
  fixedFloating,
}) => {
  const uploadInsurance = useUploadInsurance();
  const { uploadFiles } = useUploadTempS3Files();
  const hasRCPro = company.validInsurances.find(insurance => insurance.label === 'RC pro');

  return (
    <>
      {hasRCPro ? (
        <>Vous avez une assurance de responsabilité professionnelle valide.</>
      ) : (
        <InsuranceForm
          insuranceType={InsuranceFormType.RCPro}
          uploadInsurance={uploadInsurance}
          uploadFiles={uploadFiles}
          fixedFloating={fixedFloating}
        />
      )}
    </>
  );
};
