import { UserTracking } from '@travauxlib/shared/src/api/UserTracking';
import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import { ProUserProfileView } from '@travauxlib/shared/src/types/api/common/ProUserProfileView';
import { ClubProFeatures, TrackingEvents } from '@travauxlib/shared/src/utils/tracking';

import { getPlanType } from 'lib/ability/abilityMap';

class IdentityTrackingClass extends Tracker<ClubProFeatures> {
  identify(profile: ProUserProfileView): void {
    // Since indentify and people method are not considered as tracking, we need to disable
    // them manually during testing
    if (!APP_CONFIG.mixpanel.enabled || !profile.uuid) {
      UserTracking.disable();
      return;
    }

    if (profile) {
      // When we detect an hemea employee in production we still have to disable mixpanel
      // here cause we can't do it during the init phase
      if (profile.isHemeaEmployee && ENVIRONMENT === 'production') {
        UserTracking.disable();
        return;
      }

      const { company } = profile;
      const plan = getPlanType(company);

      UserTracking.identify(company?.id?.toString());
      UserTracking.people.set({
        $name: company.name,
        $email: company.email,
        Plan: plan,
        LabellisationStatus: company.status,
        ProUserUUID: profile.uuid,
        isHemeaEmployee: profile.isHemeaEmployee,
      });
    }
  }

  onLogout(properties: { source: 'Clique sidebar' }): void {
    this.sendTracking({ event: TrackingEvents.LoggedOut, properties });
  }
}

export const IdentityTracking = new IdentityTrackingClass(ClubProFeatures.Identity);
