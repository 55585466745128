import React from 'react';

import { useProfile } from 'api/useProfile';
import { useIsAllowed } from 'hooks/usePermissions';
import {
  parametresAbonnementUrl,
  parametresConditionsUrl,
  parametresEntrepriseUrl,
  parametresLabellisationUrl,
  parametresProfileUrl,
  parametresUtilisateursUrl,
} from 'utils/urls';

import { SideBarLink } from '../SideBarLink';

export const ParametersSideBar: React.FC = () => {
  const canAccessLabellisationDossier = useIsAllowed('Labellisation', 'manage');
  const { profile } = useProfile();

  return (
    <div className="!flex !flex-col !h-full !pb-sm ">
      <div className="pb-md font-bold px-sm text-h4">Paramètres</div>
      <SideBarLink label="Mon Profil" path={parametresProfileUrl} />
      {profile.isProCompanyAdmin && (
        <>
          <SideBarLink
            data-testid="abonnement-sidebar"
            label="Abonnement"
            path={parametresAbonnementUrl}
          />
          <SideBarLink label="Mon Entreprise" path={parametresEntrepriseUrl} />
          <SideBarLink label="Utilisateurs" path={parametresUtilisateursUrl} />
          {canAccessLabellisationDossier && (
            <SideBarLink label="Dossier de Labellisation" path={parametresLabellisationUrl} />
          )}
        </>
      )}
      <div className="!mt-auto !flex">
        <SideBarLink label="Les conditions" path={parametresConditionsUrl} bottomLink />
      </div>
    </div>
  );
};
