import React, { useCallback, useState } from 'react';

import dayjs from 'dayjs';

import PlusCircle from '@travauxlib/shared/src/components/DesignSystem/assets/PlusCircle.svg?react';
import { DevisStatuses } from '@travauxlib/shared/src/types';
import { ProDevisView } from '@travauxlib/shared/src/types/api/pro/ProDevisView';
import { devisOrProposition } from '@travauxlib/shared/src/utils/wording';

import { useIsArchitecte } from 'api/profileSelectors';
import { useDuplicateDevis } from 'features/Devis/api/useDuplicateDevis';
import { devisSearchFilters } from 'features/DevisFactures/components/DevisFactureList/utils/devisSearchFilters';

import { Header } from './Header';

import { DevisList } from '../DevisList';

const searchFiltersWithCountByStatus = (
  devisList: ProDevisView[],
): Array<{ value: string; label: string }> =>
  devisSearchFilters.map(filter => ({
    ...filter,
    label: `${filter.label} (${devisList.filter(devis => devis.status === filter.value).length})`,
  }));

type Props = {
  devisList: ProDevisView[];
  addNewDevis: () => void;
  isHemea: boolean;
  isBlockTS: boolean;
};

const equivalentStatuses: { [Status in DevisStatuses]?: DevisStatuses[] } = {
  to_be_revised: [DevisStatuses.to_be_revised_hemea],
  sent: [DevisStatuses.sent_hemea],
  rejected: [DevisStatuses.rejected_hemea],
};

export const DevisBlock: React.FC<Props> = ({ devisList, addNewDevis, isHemea, isBlockTS }) => {
  const isArchitecte = useIsArchitecte();
  const hasOneDevisSigned = devisList.some(devis => devis.status === 'signed');
  const [devisFilters, setDevisFilters] = useState(devisSearchFilters.map(({ value }) => value));
  const { duplicateDevis } = useDuplicateDevis();

  const handleUpdateFilters = (updatedFilters: Array<DevisStatuses>): void =>
    setDevisFilters(updatedFilters);

  const filteredDevisList = devisList.filter(devis =>
    devisFilters.some(
      status => devis.status === status || equivalentStatuses[status]?.includes(devis.status),
    ),
  );

  const suffixe = filteredDevisList.length > 1 ? 's' : '';
  const sortedByCreationDevis = devisList
    .sort((devisA, devisB) => dayjs(devisA.createdAt).diff(dayjs(devisB.createdAt)))
    .reverse();

  const onNouvelleVersionClick = useCallback(() => {
    if (isBlockTS && isHemea) {
      duplicateDevis({
        devis: sortedByCreationDevis[0],
        dealUuid: devisList[0].dealUuid,
      });
    } else {
      addNewDevis();
    }
  }, [addNewDevis]);

  return (
    <div className="relative">
      <div className="rounded pb-xxs mb-xs sm-desktop:mb-0 bg-primarylighter">
        <Header
          projectTitle={devisList[0].projectTitle}
          projectUuid={devisList[0].projectUuid}
          dealUuid={devisList[0].dealUuid}
          subTitle={`${filteredDevisList.length} version${suffixe} ${devisOrProposition({
            isArchitecte,
            prefix: 'du',
          })}`}
          selectedFilters={devisFilters}
          searchFilters={searchFiltersWithCountByStatus(devisList)}
          handleUpdateFilters={handleUpdateFilters}
          isBlockTS={isBlockTS}
        />
      </div>
      <DevisList devisToDisplay={filteredDevisList} isArchitecte={isArchitecte} isHemea={isHemea} />
      {!hasOneDevisSigned && (
        <button
          className="bg-primarylighter hover:bg-primarylight font-bold w-full flex items-center justify-center py-xmd text-primary"
          type="submit"
          onClick={onNouvelleVersionClick}
        >
          <PlusCircle className="mr-xs w-lg" />
          Créer une nouvelle version
        </button>
      )}
    </div>
  );
};
