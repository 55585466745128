import { useQuery } from '@tanstack/react-query';

import { ProDevisView } from '@travauxlib/shared/src/types/api/pro/ProDevisView';
import { request } from '@travauxlib/shared/src/utils/request';

export const TRANSFER_DEVIS_KEY = 'transfer-devis';

export const useProCompanyDevisList = ({
  proCompanySlug,
  dealUuid,
}: {
  proCompanySlug: string;
  dealUuid: string;
}): {
  devisList: ProDevisView[];
  isLoading: boolean;
} => {
  const { data, isLoading } = useQuery<{ data: ProDevisView[] }>({
    queryKey: [TRANSFER_DEVIS_KEY, dealUuid],
    queryFn: () =>
      request(`${APP_CONFIG.apiURL}/admin/pro-companies/${proCompanySlug}/deals/${dealUuid}/devis`),
  });

  const devisList = data?.data || [];

  return { devisList, isLoading };
};
